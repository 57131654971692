import React from 'react';

import { Search, Question, ChatSquareTextFill, BellFill, PersonFill, Plus } from 'react-bootstrap-icons';

import cn from 'classnames';

import s from './HeaderIcon.module.css';

type IconType = 'search' | 'help' | 'analyst_support' | 'notifications' | 'user' | 'plus';

const ICONS = {
  search: <Search color="var(--primary-700)" size={14} />,
  help: <Question color="var(--primary-700)" size={24} />,
  analyst_support: <ChatSquareTextFill color="var(--primary-700)" size={15} className="mt-[1px]" />,
  notifications: <BellFill color="var(--primary-700)" size={14} />,
  user: <PersonFill color="var(--primary-700)" size={18} />,
  plus: <Plus color="var(--primary-700)" size={24} />,
};

interface HeaderIconProps {
  type: IconType;
  className?: string;
  dotColor?: string;
}

export default function HeaderIcon(props: HeaderIconProps) {
  const { type, className, dotColor } = props;

  const dotStyles: React.CSSProperties = {
    width: '10px',
    height: '10px',
    position: 'absolute',
    left: '64.29%',
    top: '0%',
    borderRadius: '5px',
    background: dotColor || 'red',
  };

  return (
    <div className={cn('relative w-7 h-7 f-center rounded-[14px] bg-silver', className)}>
      {ICONS[type]}
      {dotColor && <div style={dotStyles} />}
      {dotColor && <div className={s.dotPulse} style={dotStyles} />}
    </div>
  );
}
