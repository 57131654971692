import { forwardRef, useEffect, useRef } from 'react';

import ServiceBell from '@servicebell/widget';

import HeaderIcon from '../HeaderIcon/HeaderIcon';

export interface HelpChatButtonProps {}

export const HelpChatButton = forwardRef<HTMLButtonElement, HelpChatButtonProps>(
  (props: HelpChatButtonProps, forwardedRef) => {
    // ServiceBell's API doesn't have a check isOpen or isExpanded API.
    // So we keep track of it here.
    const isOpenRef = useRef<boolean>(false);

    const handleClick = () => {
      if (isOpenRef.current) {
        // Note:
        // The ServiceBell chat will collapse but the circle will not be hidden if the chat is open on
        // an agent's instance of app.servicebell.com.
        // This might look like a bug, but it appears to be designed behavior by ServiceBell.
        isOpenRef.current = false;
        ServiceBell('collapse');
        ServiceBell('hide');
        analytics.track('Header CloseHelpChat');
      } else {
        isOpenRef.current = true;
        ServiceBell('show');
        ServiceBell('expand');
        analytics.track('Header OpenHelpChat');
      }
    };

    // The user can close the chat from the X on the chat window.
    // Handle that scenario.
    useEffect(() => {
      const hideChat = () => {
        if (isOpenRef.current) {
          isOpenRef.current = false;
          ServiceBell('hide');
          analytics.track('HelpChat Close');
        }
      };
      window.addEventListener('sb:collapse', hideChat);
      return () => {
        window.removeEventListener('sb:collapse', hideChat);
      };
    }, []);

    return (
      <button onClick={handleClick} ref={forwardedRef} className="ml-2">
        <HeaderIcon type="analyst_support" className="bg-silver" />
      </button>
    );
  },
);

export default HelpChatButton;
