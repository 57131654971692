import { FlowchartQueryModel, FlowchartVertex, SourceTable } from '../model/FlowchartQueryModel';

import ToolbarItem, { ToolbarItemDefinition } from './ToolbarItem/ToolbarItem';

export interface ToolbarProps {
  flowchartModel: FlowchartQueryModel;
}

const Toolbar = (props: ToolbarProps) => {
  const { vertices } = props.flowchartModel;
  const items: ToolbarItemDefinition[] = [
    {
      id: 'source_table',
      icon: 'Table',
      title: 'Source Table',
      description:
        'All queries start with one or more source tables. Source tables are the input to all operations. Each operation outputs a new table that can be used as the input to the next operation.',
      img: 'source_table.png',
    },
    {
      id: 'select_columns',
      icon: 'LayoutThreeColumns',
      title: 'Pick Columns',
      description: 'Pick a subset of the columns, rename columns, and/or reorder columns.',
      img: 'select_columns.png',
    },
    // {
    //   id: 'union',
    //   icon: 'Union',
    //   title: 'Union Tables',
    //   description: 'Combine the rows of two tables that have the same columns into one table.',
    //   img: 'union_tables.png',
    // },
    {
      id: 'join',
      icon: 'ListColumnsReverse',
      title: 'Join Tables',
      description: 'Combine the data of two tables by matching column values.',
      img: 'join_tables.png',
    },
    // {
    //   id: 'where',
    //   icon: 'Filter',
    //   title: 'Filter',
    //   description: 'Filter down to rows that match certain criteria.',
    //   img: 'join_tables.png',
    // },
    // {
    //   id: 'group_by',
    //   icon: 'Grid1x2',
    //   title: 'Group Rows By Column',
    //   description:
    //     'Group rows by like column values and perform aggregate functions on the groups like averages.',
    //   img: 'group_by.png',
    // },
    // {
    //   id: 'order_by',
    //   icon: 'SortAlphaDown',
    //   title: 'Order Rows',
    //   description: 'Order the rows in your table by column values.',
    //   img: 'order_by.png',
    // },
  ];

  const isItemEnabled = (item: ToolbarItemDefinition, vertices: FlowchartVertex[]): boolean => {
    if (item.id === 'source_table') {
      return true;
    }

    return vertices.some((v) => v.type === 'source_table' && (v as SourceTable).table !== null);
  };

  return (
    <div className="">
      <div className="w-[56px] p-4 grid grid-cols-1 gap-4 rounded bg-sec-blue-gray-100">
        {items.map((i) => (
          <ToolbarItem key={i.id} {...i} enabled={isItemEnabled(i, vertices)} />
        ))}
      </div>
    </div>
  );
};

export default Toolbar;
