import React from 'react';

import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';
import CodeEditor, { editorHeight } from 'components/inputs/basic/CodeEditor/CodeEditor';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import { formatSql } from 'components/query/queryUtil';
import { AIAssistantChatMessage } from 'components/query/useAIAssistant';
import { useDatabaseAccount } from 'context/AuthContext';

import CopiedTip from '../TableExplorer/CopiedTip';
import CopyButton, { useCopyButton } from '../TableExplorer/CopyButton';
import { EditorRunProps } from '../useSqlEditor';

// Define our own theme for putting the editor in the speech bubbles.
// The editor is disabled but we want to make it look
// like the normal white, enabled editor.
const AI_ASSISTANT_THEME_OVERRIDES = {
  '& .cm-scroller': {
    backgroundColor: 'white',
  },

  // Disable the activeLine so it isn't distracting.
  // The activeLine is on a higher layer than the text selection highlighting.
  // So it blocks the text selection highlighting on the layer under it unless we make it transparent.
  '& .cm-activeLine': {
    backgroundColor: 'transparent',
  },
};

interface AIAssistantRowProps {
  message: AIAssistantChatMessage;
  setSqlAndRun?: (sql: string) => Promise<EditorRunProps>;
}

// Function to determine if a string is SQL so we can format it differently and add a copy button
const isSQL = (sql: string) => {
  const trimmedSql = sql.trim();
  // This regex says the text must start with "with" or "select" and then has some other stuff, and then have "from"
  const selectWithRegex = /^(WITH\b[\s\S]*?\bSELECT\b|SELECT\b)[\s\S]*?\bFROM\b/i;
  return selectWithRegex.test(trimmedSql);
};

const AIAssistantRow = React.memo((props: AIAssistantRowProps) => {
  const { message: messageObj, setSqlAndRun } = props;
  const { message, role } = messageObj;
  const databaseType = useDatabaseAccount().type;

  const messageIsSQL = role === 'assistant' && isSQL(message);

  // We are doing this exercise because the scrolling to the bottom of the chat window needs deterministic heights
  // and the SQLEditor editor changes its height over several render cycles and we need to set the height on the
  // first render cycle so that scrolling to the bottom of the chat window works correctly.
  const formattedMessage = messageIsSQL ? formatSql(message, databaseType) : message;
  const rowCount = formattedMessage.split('\n').length;
  const sqlHeight = editorHeight(rowCount);

  const { showCopied, copiedRef, handleCopy } = useCopyButton();

  const handleCopyCode = (
    value: string,
    targetRef: React.MutableRefObject<HTMLButtonElement | null>,
  ) => {
    handleCopy(value, targetRef);
  };

  const handleReplaceCode = () => {
    if (setSqlAndRun) {
      setSqlAndRun(formattedMessage);
    }
  };

  return (
    <div
      className={cn('px-2 pt-[10px] last:pb-[10px] flex', {
        'ml-10 justify-end': role === 'user',
        'mr-10 justify-start': role !== 'user',
      })}
    >
      <div className="w-full">
        <div
          className={cn('py-1 px-2 rounded w-full', {
            'bg-sec-blue-gray-400 text-white': role === 'user',
            'bg-white': role !== 'user',
          })}
        >
          {messageIsSQL ? (
            <div className="w-full" style={{ height: `${sqlHeight}px` }}>
              <CodeEditor
                disableGutters={true}
                value={formattedMessage}
                height="100%"
                mode="snowflake"
                themeOverrides={AI_ASSISTANT_THEME_OVERRIDES}
              />
            </div>
          ) : (
            <div className="whitespace-pre-wrap relative pr-6">{message}</div>
          )}
        </div>
        {messageIsSQL && (
          <div className="pt-[3px] f-row-y-center justify-start">
            <TooltipTrigger tip="Copy query" forceShow={showCopied === true ? false : undefined}>
              <CopyButton value={formattedMessage} onCopy={handleCopyCode} />
            </TooltipTrigger>
            <TooltipTrigger tip="Copy query into editor and run it.">
              <IconButton
                icon="BoxArrowRight"
                onClick={handleReplaceCode}
                variant="lightDullTransparent"
                size="small"
              />
            </TooltipTrigger>
            <CopiedTip show={showCopied} targetRef={copiedRef} />
          </div>
        )}
      </div>
    </div>
  );
});

export default AIAssistantRow;
