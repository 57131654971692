// If you need a new bootstrap icon in an icon button, add it to this list.
// `import *` adds a lot of unused icons that makes the build big, which we don't want.
import {
  ArrowBarDown,
  ArrowBarLeft,
  ArrowBarRight,
  ArrowBarUp,
  ArrowClockwise,
  ArrowLeftRight,
  ArrowRepeat,
  BoxArrowLeft,
  BoxArrowRight,
  BoxArrowUpRight,
  CameraFill,
  Check2,
  ChevronDoubleDown,
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronDoubleUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard2Plus,
  Clock,
  ClockFill,
  Download,
  EmojiExpressionless,
  EmojiFrown,
  EmojiSmile,
  ExclamationCircle,
  Eye,
  EyeFill,
  EyeSlash,
  EyeSlashFill,
  FileBarGraphFill,
  Files,
  Folder,
  FolderFill,
  Gear,
  Github,
  LayoutSplit,
  Lightbulb,
  Link45deg,
  PaletteFill,
  PencilSquare,
  Pin,
  PinFill,
  Plus,
  Search,
  Server,
  Star,
  StarFill,
  Stars,
  Tag,
  TagFill,
  Trash,
  XCircle,
  XLg,
  ZoomIn,
  ZoomOut,
} from 'react-bootstrap-icons';

// GRUMBLE: I couldn't figure out how to alias the named imports object, so we have to repeat ourselves.
// Top honors to the first person to figure out how to not repeat the list, preserve import tree shaking, and preserve type safety.
const usedIcons = {
  ArrowBarDown,
  ArrowBarLeft,
  ArrowBarRight,
  ArrowBarUp,
  ArrowClockwise,
  ArrowLeftRight,
  ArrowRepeat,
  BoxArrowLeft,
  BoxArrowRight,
  BoxArrowUpRight,
  CameraFill,
  Check2,
  ChevronDoubleDown,
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronDoubleUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard2Plus,
  Clock,
  ClockFill,
  Download,
  EmojiExpressionless,
  EmojiFrown,
  EmojiSmile,
  ExclamationCircle,
  Eye,
  EyeFill,
  EyeSlash,
  EyeSlashFill,
  FileBarGraphFill,
  Files,
  Folder,
  FolderFill,
  Gear,
  Github,
  LayoutSplit,
  Lightbulb,
  Link45deg,
  PaletteFill,
  PencilSquare,
  Pin,
  PinFill,
  Plus,
  Search,
  Server,
  Star,
  StarFill,
  Stars,
  Tag,
  TagFill,
  Trash,
  XCircle,
  XLg,
  ZoomIn,
  ZoomOut,
};

export default usedIcons;
