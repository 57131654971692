import { useContext, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { TableModelsContext } from 'model_layer/TableModelsContext';

export type CreateAlertSource = 'HomepageStatusWidget' | 'DataAlertsPage' | 'MoreActionsButton';

const useCreateDataAlerts = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const { tables, tablesByFullName } = useContext(TableModelsContext);
  const history = useHistory();

  const tableOptions = useMemo(() => tables.map((table) => table.full_name), [tables]);
  const filteredTableOptions = useMemo(
    () => tableOptions.filter((table) => table.toLowerCase().includes(filterValue.toLowerCase())),
    [tableOptions, filterValue],
  );

  const filterOptions = (filter: string) => {
    setFilterValue(filter);
  };

  const handleOpenCreateAlertModal = (eventLocation: CreateAlertSource) => {
    analytics.track(`${eventLocation} OpenCreateAlertModal`);
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateAlertModal = (eventLocation: CreateAlertSource) => {
    analytics.track(`${eventLocation} CloseCreateAlertModal`);
    setIsCreateModalOpen(false);
  };

  const handleSelectCreateAlertModal = (tableName: string, eventLocation: CreateAlertSource) => {
    analytics.track(`${eventLocation} SelectCreateAlertModal`);
    setIsCreateModalOpen(false);

    const table = tablesByFullName[tableName];
    history.push(`/tables/${table.id}/alerts`, { createAlert: true });
  };

  return {
    isCreateModalOpen,
    tableOptionsForCreate: filteredTableOptions.slice(0, 10),
    filterTableOptionsForCreate: filterOptions,
    onOpenCreateAlertModal: handleOpenCreateAlertModal,
    onCloseCreateAlertModal: handleCloseCreateAlertModal,
    onSelectCreateAlertModal: handleSelectCreateAlertModal,
  };
};

export default useCreateDataAlerts;
