import { useMemo, useState } from 'react';

import { uniq } from 'lodash';

import { AggTable } from 'api/tableAPI';
import TablePickerExplorer from 'components/inputs/basic/SingleTablePicker/TablePickerExplorer/TablePickerExplorer';
import {
  FlowchartQueryModel,
  SourceTable,
} from 'components/query/FlowchartEditor/model/FlowchartQueryModel';

import VertexModal from '../../VertexModal/VertexModal';

export interface SourceTableModalProps {
  vertex: SourceTable;
  flowchartQueryModel: FlowchartQueryModel;
  onCancel(): void;
  onSave(table: AggTable): void;
}

const SourceTableModal = (props: SourceTableModalProps) => {
  const { vertex, flowchartQueryModel, onCancel, onSave } = props;
  const [selectedTable, setSelectedTable] = useState<AggTable | null>(vertex.table);

  const tablesNamesUsedInSql: string[] = useMemo(() => {
    const sources = flowchartQueryModel.vertices.filter(
      (v) => v.type === 'source_table',
    ) as SourceTable[];
    const tablesNamesUsedInSql: string[] = uniq(
      sources.filter((s) => s.table !== null).map((s) => s.table?.full_name || ''),
    );
    return tablesNamesUsedInSql;
  }, [flowchartQueryModel]);

  return (
    <VertexModal
      header="Pick Table"
      containerClass="w-[700px] h-[90%]"
      saveButtonCopy={selectedTable ? `Use ${selectedTable.name}` : undefined}
      onCancel={onCancel}
      onSave={selectedTable ? () => onSave(selectedTable) : undefined}
    >
      <div className="h-full">
        <TablePickerExplorer
          selectedTable={selectedTable}
          tableDraggable={false}
          tablesNamesUsedInSql={tablesNamesUsedInSql}
          setSelectedTable={setSelectedTable}
          onPickTable={onSave}
        />
      </div>
    </VertexModal>
  );
};

export default SourceTableModal;
