import React from 'react';

import { GripHorizontal } from 'react-bootstrap-icons';
import { ReactSortable } from 'react-sortablejs';

import classNames from 'classnames';

import styles from './DragAndDropList.module.css';

export type SortableListItem = { id: string; [key: string]: any };

interface Props {
  dataList: SortableListItem[];
  gripButtonClass?: string;
  setDataList: (updatedDataList: SortableListItem[]) => void;
  renderListItem: (data: SortableListItem) => React.ReactNode;
}

const DragAndDropList = (props: Props) => {
  const { dataList, gripButtonClass, setDataList, renderListItem } = props;

  return (
    <ReactSortable
      tag="ul"
      list={dataList}
      setList={setDataList}
      handle=".dragHandle"
      animation={150}
      /*
        Hide default ghost element shown by browser to make it easier to see what's happening
        when element is dragged
      */
      dragClass={styles.defaultGhostHidden}
      // Highlight selected list item to easily see what element is moving and what position it is at
      chosenClass={styles.listItemSelected}
      // Set to true to clean up cursor issues when dragging
      forceFallback
      // Event handlers to set cursor to grabbing when dragging
      onStart={(_evt) => {
        document.documentElement.classList.add(styles.draggingHandle);
      }}
      onEnd={(_evt) => {
        document.documentElement.classList.remove(styles.draggingHandle);
      }}
    >
      {dataList.map((data) => {
        return (
          <li key={data.id} className={styles.listItemContainer}>
            <button
              type="button"
              className={classNames('dragHandle', styles.dragHandle, gripButtonClass)}
            >
              <GripHorizontal />
            </button>
            <div className="ml-2 flex-auto">{renderListItem(data)}</div>
          </li>
        );
      })}
    </ReactSortable>
  );
};

export default DragAndDropList;
