import React from 'react';

import { ColumnValue } from '../../../../../../model/FlowchartQueryModel';

interface SelectColumnRowHoverCardProps {
  columnValue: ColumnValue;
  samples: string[];
}

const SelectColumnRowHoverCard = (props: SelectColumnRowHoverCardProps) => {
  const { columnValue, samples } = props;
  const { value, column } = columnValue;

  const rowClass =
    'p-1 text-sm overflow-hidden whitespace-nowrap border-b border-[#dee2e6] last:border-none';

  return (
    <div className="f-col">
      <div>
        {<strong>{value}</strong>}
        {column?.type && (
          <>
            <span>: </span>
            <span className="text-pri-gray-400">{column?.type}</span>
          </>
        )}
      </div>

      {column?.description && (
        <>
          <strong className="mt-2">Description:</strong>
          <div>{column?.description}</div>
        </>
      )}

      <strong className="mt-2">Sample Rows:</strong>
      {samples.length > 0 ? (
        <>
          {samples.map((s, i) => (
            <div key={i} className={rowClass}>
              {s}
            </div>
          ))}
        </>
      ) : (
        <div key="ALL_NULL" className={rowClass}>
          Zero non-NULL rows in first 100 rows.
        </div>
      )}
    </div>
  );
};

export default SelectColumnRowHoverCard;
