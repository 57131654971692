import React, { memo } from 'react';

import cn from 'classnames';

import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';

import s from './SearchableCheckboxPicker.module.css';

// Props that do not change from row to row
// Wrap all of these methods in useCallback() so SearchableCheckboxPickerRow memoization will work.
export interface SearchableCheckboxPickerEveryRowProps<SearchableObject> {
  objectsRowClass?: string;
  objectsBoxClass?: string;
  isChecked(o: SearchableObject): boolean;
  hasChanged(o: SearchableObject): boolean; // Was this object checked or unchecked since last save?
  onToggleCheck(o: SearchableObject): void;
  renderObject(o: SearchableObject): React.ReactNode;
  getDescription?(o: SearchableObject): string;
}

// Props that change from row to row
export interface SearchableCheckboxPickerRowProps<SearchableObject>
  extends SearchableCheckboxPickerEveryRowProps<SearchableObject> {
  checkboxName: string;
  object: SearchableObject;
}

const SearchableCheckboxPickerRow = memo(
  <SearchableObject extends object>(props: SearchableCheckboxPickerRowProps<SearchableObject>) => {
    const {
      checkboxName,
      object,
      objectsRowClass,
      objectsBoxClass,
      isChecked,
      hasChanged,
      onToggleCheck,
      renderObject,
      getDescription,
    } = props;

    return (
      <div
        onClick={() => onToggleCheck(object)}
        className={cn(s.listItem, {
          [s.hasChanges]: hasChanged(object),
        })}
      >
        <div className={cn(s.objectRow, objectsRowClass)}>
          <div className={cn(s.objectBox, objectsBoxClass)}>
            <Checkbox
              variant="blue_gray"
              name={checkboxName}
              checked={isChecked(object)}
              readOnly={true} // onChange is handled by the entire row, so the input is readOnly
            />
            {renderObject(object)}
          </div>
        </div>
        {getDescription && <div className={s.description}>{getDescription(object)}</div>}
      </div>
    );
  },
);

export default SearchableCheckboxPickerRow;
