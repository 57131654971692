import deepEqual from 'fast-deep-equal';

import { ColumnValue } from '../../../model/FlowchartQueryModel';

import VertexModal from '../../VertexModal/VertexModal';

import ColumnSelector from './ColumnSelector/ColumnSelector';
import useColumnSelector from './ColumnSelector/useColumnSelector';

export interface SelectColumnsModalProps {
  pickTitle: string;
  availableColumns: ColumnValue[];
  selectedColumns?: ColumnValue[];
  onCancel(): void;
  onSave(columnSelections?: ColumnValue[]): void;
}

const SelectColumnsModal = (props: SelectColumnsModalProps) => {
  const { pickTitle, availableColumns, selectedColumns, onCancel, onSave } = props;
  const columnSelectorState = useColumnSelector(availableColumns, selectedColumns);
  const { currentSelections, formError } = columnSelectorState;

  // Save undefined if the user is using the default set of columns.
  // Save the entire list if a single prop is changed or
  // if the order of the columns in the list changes.
  const handleSave = () => {
    const allDefaultOrderAndValue = deepEqual(availableColumns, currentSelections);
    const toSave = allDefaultOrderAndValue ? undefined : currentSelections;
    onSave(toSave);
  };

  return (
    <VertexModal
      header={pickTitle}
      containerClass="w-[700px] h-[90%]"
      error={formError}
      onCancel={onCancel}
      onSave={handleSave}
    >
      <div className="h-full">
        <ColumnSelector {...columnSelectorState} />
      </div>
    </VertexModal>
  );
};

export default SelectColumnsModal;
