import React, { ForwardedRef, ReactElement, Ref, useState } from 'react';

import cn from 'classnames';

import Button from 'components/inputs/basic/Button/Button';
import TextInput, { TextInputProps } from 'components/inputs/basic/TextInput/TextInput';

import SearchableCheckboxPickerRow, {
  SearchableCheckboxPickerEveryRowProps,
} from './SearchableCheckboxPickerRow';

import s from './SearchableCheckboxPicker.module.css';

interface SearchableCheckboxPickerProps<SearchableObject> {
  objects: SearchableObject[];
  containerClass?: string;
  inputWrapperClass?: string;
  listClass?: string; // You probably want to use this to set the maxHeight of the options list
  listObjectsClass?: string; // Might need to set this to overflow-auto
  objectsRowClass?: string;
  objectsBoxClass?: string;
  textInputProps?: Partial<Omit<TextInputProps, 'ref'>>;
  autoFocus?: boolean;
  getKey(o: SearchableObject, idx: number): string; // Returns id or some other unique key
  isChecked(o: SearchableObject): boolean;
  hasChanged(o: SearchableObject): boolean; // Was this object checked or unchecked since last save?
  onToggleCheck(o: SearchableObject): void;
  matchesFilter(o: SearchableObject, filter: string): boolean;
  renderNoObjects(): React.ReactNode; // You probably want to render a <NoObjectsAlert/> or a <FixMeAlert/> depending on how big of a deal this is.
  renderNoMatchesFound(): React.ReactNode; // You probably want to render a <NoMatchesFountAlert/>
  renderHeaderRow?(): React.ReactNode;
  renderObject(o: SearchableObject): React.ReactNode;
  getDescription?(o: SearchableObject): string;
  onSelectAll?(): void;
  onDeselectAll?(): void;
}

const SearchableCheckboxPicker = <SearchableObject extends object>(
  props: SearchableCheckboxPickerProps<SearchableObject>,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  const {
    objects,
    containerClass,
    inputWrapperClass,
    listClass,
    listObjectsClass,
    objectsRowClass,
    objectsBoxClass,
    textInputProps,
    autoFocus = true,
    getKey,
    isChecked,
    hasChanged,
    onToggleCheck,
    matchesFilter,
    renderNoObjects,
    renderNoMatchesFound,
    renderHeaderRow,
    renderObject,
    getDescription,
    onSelectAll,
    onDeselectAll,
  } = props;

  const everyRowProps: SearchableCheckboxPickerEveryRowProps<SearchableObject> = {
    objectsRowClass,
    objectsBoxClass,
    isChecked,
    hasChanged,
    onToggleCheck,
    renderObject,
    getDescription,
  };

  const [filter, setFilter] = useState<string>(
    textInputProps?.value ? textInputProps?.value.toString() : '',
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilter(event.target.value);
  };

  let filteredObjects = objects;
  if (filter) {
    filteredObjects = objects.filter((o: SearchableObject) => {
      return matchesFilter(o, filter.toLowerCase());
    });
  }

  const actualInputWrapperClass = cn(inputWrapperClass, {
    'f-row-y-center': onSelectAll || onDeselectAll,
  });

  return (
    <div className={containerClass}>
      <div className={actualInputWrapperClass}>
        <TextInput
          ref={forwardedRef}
          name="object-search"
          placeholder="Search"
          autoFocus={autoFocus}
          autoComplete="off"
          maxLength={50}
          {...textInputProps}
          value={filter}
          onChange={handleFilterChange}
        />
        {onSelectAll && (
          <Button onClick={onSelectAll} size="form" className="ml-2">
            Select All
          </Button>
        )}
        {onDeselectAll && (
          <Button onClick={onDeselectAll} size="form" className="ml-2">
            Deselect All
          </Button>
        )}
      </div>
      <div className={cn(s.list, listClass)}>
        {objects.length === 0 ? (
          renderNoObjects()
        ) : (
          <>
            {filteredObjects.length === 0 ? (
              renderNoMatchesFound()
            ) : (
              <div className={listObjectsClass}>
                {renderHeaderRow && renderHeaderRow()}
                {filteredObjects.map((t, idx) => {
                  const key = getKey(t, idx);
                  return (
                    <SearchableCheckboxPickerRow
                      key={key}
                      {...everyRowProps}
                      object={t}
                      checkboxName={key}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// ForwardRef doesn't work with generics so cast.
// See: https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
const CastSearchableCheckboxPicker = React.forwardRef(SearchableCheckboxPicker) as <
  SearchableObject extends object,
>(
  p: SearchableCheckboxPickerProps<SearchableObject> & { ref?: Ref<HTMLInputElement> },
) => ReactElement;

export default CastSearchableCheckboxPicker;
