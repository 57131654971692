import { useCallback, useMemo, useRef, useState } from 'react';

import deepEqual from 'fast-deep-equal';

import { TextInputProps } from 'components/inputs/basic/TextInput/TextInput';
import SearchableCheckboxPicker from 'components/inputs/composite/SearchableCheckboxPicker/SearchableCheckboxPicker';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';
import { moveTo } from 'utils/Array';

import { ColumnValue } from '../../../../model/FlowchartQueryModel';

import SelectColumnRow, { ColumnValueID } from './SelectColumnsRow/SelectColumnsRow';

// Append UI specific props to QueryModel object so we can loop over one array of objects.
interface ColumnSelectorRow {
  cv: ColumnValue;
  aliasError: string;
  isBeingDragged: boolean;
  isDraggedOver: boolean;
}

export interface ColumnSelectorProps {
  initialSelections: ColumnValue[];
  currentSelections: ColumnValue[];
  aliasErrors: Record<ColumnValueID, string>;
  textInputProps?: Partial<Omit<TextInputProps, 'ref'>>;
  setCurrentSelections: React.Dispatch<React.SetStateAction<ColumnValue[]>>;
}

const ColumnSelector = (props: ColumnSelectorProps) => {
  const { initialSelections, currentSelections, aliasErrors, textInputProps, setCurrentSelections } =
    props;
  const [draggingColumn, setDraggingColumn] = useState<ColumnValueID>('');
  const [dragEntered, setDragEntered] = useState<ColumnValueID>('');

  // Make readonly copies of state so callbacks aren't redefined, triggering renders.
  const draggingColumnRef = useRef<ColumnValueID>('');
  const dragEnteredRef = useRef<ColumnValueID>('');
  const currentSelectionsRef = useRef<ColumnValue[]>([]);
  draggingColumnRef.current = draggingColumn;
  dragEnteredRef.current = dragEntered;
  currentSelectionsRef.current = currentSelections;

  /*******************************************************************************
   * Utilities to make updates easier
   ******************************************************************************/
  const updateSelection = useCallback(
    (columnValueID: ColumnValueID, updateFound: (selection: ColumnValue) => ColumnValue) => {
      const currentSelections = currentSelectionsRef.current;
      const currentIndex = currentSelections.findIndex((cs) => cs.id === columnValueID);

      if (currentIndex > -1) {
        const updatedSelections = [...currentSelectionsRef.current];
        updatedSelections[currentIndex] = updateFound(updatedSelections[currentIndex]);
        setCurrentSelections(updatedSelections);
      }
    },
    [setCurrentSelections],
  );

  /*******************************************************************************
   * SearchableCheckboxPicker Props
   ******************************************************************************/
  const getKey = useCallback((row: ColumnSelectorRow) => row.cv.id, []);
  const isChecked = useCallback((row: ColumnSelectorRow) => row.cv.picked, []);
  const columnHasChanged = useCallback(
    (row: ColumnSelectorRow) => {
      const originalColumn = initialSelections.find((sc) => sc.id === row.cv.id);
      return !deepEqual(originalColumn, row.cv);
    },
    [initialSelections],
  );
  const handleToggleCheck = useCallback(
    (row: ColumnSelectorRow) => {
      const updateFound = (oldSelection: ColumnValue) => {
        const newSelection: ColumnValue = {
          ...oldSelection,
          picked: !oldSelection.picked,
        };
        return newSelection;
      };
      updateSelection(row.cv.id, updateFound);
    },
    [updateSelection],
  );
  const matchesFilter = (row: ColumnSelectorRow, filter: string) =>
    row.cv.value.toLowerCase().includes(filter.toLowerCase());
  const renderNoObjects = () => <NoObjectsAlert className="m-4" heading="You don't have any columns." />;
  const renderNoMatchesFound = () => (
    <NoMatchesFoundAlert className="m-4" heading="No matching columns." />
  );

  // Append UI specific props to QueryModel object so we can loop over one array of objects.
  const convertRow = useCallback(
    (cv: ColumnValue): ColumnSelectorRow => ({
      cv,
      aliasError: aliasErrors[cv.id],
      isBeingDragged: draggingColumn === cv.id,
      isDraggedOver: dragEntered === cv.id,
    }),
    [aliasErrors, draggingColumn, dragEntered],
  );

  // New objects trigger rerenders of column selector rows.
  // Reuse old object references if the objects are the same.
  const rowCacheRef = useRef<Record<string, ColumnSelectorRow>>({});
  const currentRows: ColumnSelectorRow[] = useMemo(
    () =>
      currentSelections.map((cs) => {
        const newRow = convertRow(cs);
        const cachedRow = rowCacheRef.current[newRow.cv.id];
        if (cachedRow && deepEqual(cachedRow, newRow)) {
          return cachedRow;
        }
        rowCacheRef.current[newRow.cv.id] = newRow;
        return newRow;
      }),
    [currentSelections, convertRow],
  );

  /*******************************************************************************
   * SelectColumnRow Props
   *
   * Note:
   * There can be a lot of SelectColumnRows, so these are wrapped
   * in useCallback() to allow React memoization to only have to rerender
   * the rows that change.
   ******************************************************************************/
  const onDragStart = useCallback((column: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
    setDraggingColumn(column);
    event.dataTransfer.setData('text/plain', column);
    event.dataTransfer.effectAllowed = 'move';
  }, []);

  const onDragEnd = useCallback(() => {
    setDraggingColumn('');
  }, []);

  const onDragOver = useCallback((event: React.DragEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onDragEnter = useCallback(
    (enteredColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
      event.preventDefault();

      // You can drag onto yourself
      if (enteredColumn !== dragEnteredRef.current) {
        setDragEntered(enteredColumn);
      }
    },
    [dragEnteredRef],
  );

  const onDragLeave = useCallback(
    (leftColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
      if (leftColumn === dragEnteredRef.current) {
        // DO NOT change state if the element I "left to" is my descendant.
        // That is not a real leave.
        // @ts-ignore
        const isMyDescendant = event.target.contains(event.relatedTarget);
        if (!isMyDescendant) {
          setDragEntered('');
        }
      }
    },
    [dragEnteredRef],
  );

  const onDropColumn = useCallback(
    (draggedColumn: ColumnValueID, droppedOnColumn: ColumnValueID) => {
      const currentSelections = currentSelectionsRef.current;
      const draggedIndex = currentSelections.findIndex((cs) => cs.id === draggedColumn);
      const droppedOnIndex = currentSelections.findIndex((cs) => cs.id === droppedOnColumn);
      if (draggedIndex >= 0 && droppedOnIndex >= 0) {
        setCurrentSelections(moveTo(currentSelections, draggedIndex, droppedOnIndex));
      }
    },
    [currentSelectionsRef, setCurrentSelections],
  );

  const onDrop = useCallback(
    (droppedOnColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setDraggingColumn('');
      setDragEntered('');

      // You cannot drop onto yourself
      const draggedColumn = event.dataTransfer.getData('text/plain');
      if (droppedOnColumn !== draggedColumn) {
        onDropColumn(draggedColumn, droppedOnColumn);
      }
    },
    [onDropColumn],
  );

  const onUpdateAlias = useCallback(
    (columnValueID: string, alias: string) => {
      const updateFound = (oldSelection: ColumnValue) => {
        const newSelection: ColumnValue = {
          ...oldSelection,
          alias,
        };
        // Do not save empty aliases
        if (alias === '') {
          delete newSelection.alias;
        }
        return newSelection;
      };
      updateSelection(columnValueID, updateFound);
    },
    [updateSelection],
  );

  const renderHeaderRow = () => (
    <div className="w-full pl-2 pr-3 pt-2 f-between font-bold">
      <div>Column</div>
      <div className="w-[300px]">Rename To (Optional)</div>
    </div>
  );

  const renderObject = useCallback(
    (row: ColumnSelectorRow) => (
      <SelectColumnRow
        columnValue={row.cv}
        aliasError={row.aliasError}
        samples={[]}
        isBeingDragged={row.isBeingDragged}
        isDraggedOver={row.isDraggedOver}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onUpdateAlias={onUpdateAlias}
      />
    ),
    [onDragStart, onDragEnd, onDragOver, onDragEnter, onDragLeave, onDrop, onUpdateAlias],
  );

  /*******************************************************************************
   * ColumnSelector/Form Level Props
   ******************************************************************************/
  const handleSelectAll = () => {
    const newSelections = currentSelections.map((c) => ({ ...c, picked: true }));
    setCurrentSelections(newSelections);
  };

  const handleDeselectAll = () => {
    const newSelections = currentSelections.map((c) => ({ ...c, picked: false }));
    setCurrentSelections(newSelections);
  };

  return (
    <SearchableCheckboxPicker
      objects={currentRows}
      containerClass="h-full f-col"
      inputWrapperClass="pt-4 px-4"
      listClass="h-full"
      listObjectsClass="px-4 pb-4 overflow-auto"
      objectsBoxClass="!w-full"
      textInputProps={textInputProps}
      getKey={getKey}
      isChecked={isChecked}
      hasChanged={columnHasChanged}
      onToggleCheck={handleToggleCheck}
      matchesFilter={matchesFilter}
      renderNoObjects={renderNoObjects}
      renderNoMatchesFound={renderNoMatchesFound}
      renderHeaderRow={renderHeaderRow}
      renderObject={renderObject}
      // getDescription={getDescription}
      onSelectAll={handleSelectAll}
      onDeselectAll={handleDeselectAll}
    />
  );
};

export default ColumnSelector;
