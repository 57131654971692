import React, { useCallback } from 'react';

import { Tag } from 'api/tagAPI';

import TableExplorerTagExpando from '../expandos/TableExplorerTagExpando';

import GenericExpandoList, { GenericExpandoListProps, RenderExpandoProps } from './GenericExpandoList';

interface TagExpandoListProps extends Omit<GenericExpandoListProps, 'renderExpando'> {
  onToggleTag(schema: string): void;
  tags: Tag[];
}

const TagExpandoList = React.memo((props: TagExpandoListProps) => {
  const { onToggleTag, tags, ...rest } = props;

  const renderExpando = useCallback(
    (props: RenderExpandoProps) => {
      const { key, ...rest } = props;
      const tag = tags.find((t) => t.id === key) as Tag;
      if (tag) {
        return (
          <TableExplorerTagExpando
            {...rest}
            key={key}
            tag={tag}
            hideSchema={false}
            onToggleTag={onToggleTag}
          />
        );
      }

      // This should never happen unless the TableExplorerReducer's data models gets wildly corrupted.
      // This is here to make Typescript happy.
      return <div>NO MATCH</div>;
    },
    [tags, onToggleTag],
  );

  return <GenericExpandoList {...rest} renderExpando={renderExpando} />;
});

export default TagExpandoList;
