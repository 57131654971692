import { useCallback, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';

import { EditVertex } from '../../FlowchartEditor';
import {
  FlowchartVertex,
  SelectColumns,
  ColumnValue,
  SourceTable,
  Join,
  FlowchartQueryModel,
  getVertex,
  MonoParentVertex,
} from '../../model/FlowchartQueryModel';
import { getPickedAvailableColumnValues } from '../../query_builder/availableColumns';

import { SelectColumnsModalProps } from './SelectColumnsModal/SelectColumnsModal';

export interface SelectColumnsState extends EditVertex<SelectColumns, SelectColumnsModalProps> {}

export default function useSelectColumns(
  fqm: FlowchartQueryModel,
  updateVertex: (vertex: FlowchartVertex) => void,
  handleSelectVertex: (vertex: FlowchartVertex) => void,
): SelectColumnsState {
  const [vertex, setVertex] = useState<SelectColumns | null>(null);

  const onEditVertex = useCallback((vertex: SelectColumns) => {
    setVertex(vertex);
  }, []);

  const close = useCallback(() => {
    setVertex(null);
  }, []);

  const onCancel = useCallback(() => {
    close();
  }, [close]);

  const onSave = useCallback(
    (selectedColumns?: ColumnValue[]) => {
      if (vertex) {
        const updatedVertex = cloneDeep(vertex);
        updatedVertex.selectedColumns = selectedColumns;
        updateVertex(updatedVertex);
        handleSelectVertex(updatedVertex);
        close();
      }
    },
    [vertex, updateVertex, handleSelectVertex, close],
  );

  const availableColumns = useMemo(
    () => getPickedAvailableColumnValues(fqm, vertex?.id || null),
    [fqm, vertex],
  );

  const modalProps: SelectColumnsModalProps | null = useMemo(() => {
    if (vertex) {
      const newModalProps: SelectColumnsModalProps = {
        pickTitle: getPickTitle(fqm, vertex),
        availableColumns,
        selectedColumns: vertex.selectedColumns,
        onCancel,
        onSave,
      };

      return newModalProps;
    }

    return null;
  }, [fqm, vertex, availableColumns, onCancel, onSave]);

  const result: SelectColumnsState = useMemo(
    () => ({
      modalProps,
      onEditVertex,
    }),
    [modalProps, onEditVertex],
  );

  return result;
}

export function getPickTitle(fqm: FlowchartQueryModel, vertex: FlowchartVertex): string {
  return `Pick columns for ${getPickObjects(fqm, vertex, 0)}`;
}

export function getPickObjects(
  fqm: FlowchartQueryModel,
  vertex: FlowchartVertex,
  depthTraversed: number,
): string {
  if (vertex.type === 'source_table') {
    const sourceTable = vertex as SourceTable;
    return sourceTable.table?.name || 'table';
  }

  if (vertex.isMonoParent()) {
    const selectedColumns = vertex as MonoParentVertex;
    if (selectedColumns.singleParentID) {
      const singleParent = getVertex(fqm, selectedColumns.singleParentID);
      return getPickObjects(fqm, singleParent, 0); // This zero is correct
    }
  }

  if (vertex.type === 'join') {
    const join = vertex as Join;
    if (join.leftParentID && join.rightParentID && depthTraversed === 0) {
      const leftParent = getVertex(fqm, join.leftParentID);
      const rightParent = getVertex(fqm, join.rightParentID);
      return `join ${getPickObjects(fqm, leftParent, 1)} on ${getPickObjects(fqm, rightParent, 1)}`;
    }
  }

  return vertex.type.split('_').join(' ');
}
