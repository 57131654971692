import React from 'react';

import { Tag } from 'api/tagAPI';
import TagPill from 'pages/tags/TagPill';

import FilterCount from './FilterCount';
import TableExplorerGenericExpando, {
  TableExplorerCommonExpandoProps,
} from './TableExplorerGenericExpando';

interface TableExplorerTagExpandoProps extends TableExplorerCommonExpandoProps {
  // My Props
  tag: Tag;
  onToggleTag(schema: string): void;
}

const TableExplorerTagExpando = React.memo((props: TableExplorerTagExpandoProps) => {
  const {
    // My Props
    tag,
    onToggleTag,
    isFiltering,
    unfilteredCount,

    // Stuff I pass to TableExplorerGenericExpando
    tables,
    ...passToGenericExpando
  } = props;

  return (
    <TableExplorerGenericExpando
      isFiltering={isFiltering}
      unfilteredCount={unfilteredCount}
      tables={tables}
      {...passToGenericExpando}
    >
      <div
        className="tt-schema-row"
        onClick={() => {
          onToggleTag(tag.id);
        }}
      >
        <TagPill tag={tag} eventPage="TableExplorer" disableLink={true} className="!cursor-pointer" />
        <FilterCount
          isFiltering={isFiltering}
          filteredCount={tables.length}
          unfilteredCount={unfilteredCount}
        />
      </div>
    </TableExplorerGenericExpando>
  );
});

export default TableExplorerTagExpando;
