import {
  Table as SourceTableIcon,
  LayoutThreeColumns as PickColumnsIcon,
  Union as UnionIcon,
  ListColumnsReverse as JoinIcon,
  FilterCircle as WhereIcon,
  Grid1x2 as GroupByIcon,
  SortAlphaDown as OrderByIcon,
} from 'react-bootstrap-icons';

import TableIcon from 'components/primitives/icons/TableIcon/TableIcon';

import { FlowchartVertex, SourceTable } from '../model/FlowchartQueryModel';

/* Colors */
export const COLORS = {
  PRI_GRAY_700: '#344054',
  PRI_GRAY_300: '#d0d5dd',
  WHITE: '#FFFFFF',
  BRAND_PURPLE: '#4f35b3',
  SEC_PURPLE_50: '#f4f3ff',
  SEC_BLUE_LIGHT_700: '#026aa2',
  PRI_SUCCESS_200: '#a6f4c5',
  PRI_SUCCESS_700: '#027a48',
  PRI_ERROR_700: '#b42318',
  PRI_ERROR_100: '#fee4e2',
  SEC_ORANGE_700: '#c4320a',
  SEC_ORANGE_100: '#ffead5',
  PRI_WARNING_700: '#b54708',
  PRI_WARNING_200: '#fedf89',
  PRI_WARNING_50: '#fffaeb',
};

/* Graph Style Constants */
export const GRAPH_PADDING = 20;

/* Node Style Constants */
export const LINE_HEIGHT = 1.25;
export const FONT_SIZE = 10;
export const LEGEND_FONT_SIZE = 14;
export const MAX_NUM_CHARS = 30;
export const LABEL_FONT_WEIGHT = 500;
export const SUBLABEL_FONT_WEIGHT = 300;

export const VERTEX_TABLE_WIDTH = 150;
export const VERTEX_OPERATION_WIDTH = 105;
export const VERTEX_HEIGHT = 48;

export const BORDER_WIDTH = 0.5;
export const SELECTED_BORDER_WIDTH = 1.5;
export const BORDER_RADIUS = 10;

export const DEFAULT_BORDER_COLOR = COLORS.PRI_GRAY_300;
export const STYLES_BY_TYPE = {
  Normal: {
    bgColor: COLORS.WHITE,
    color: COLORS.SEC_BLUE_LIGHT_700,
    selectedBorderColor: COLORS.SEC_BLUE_LIGHT_700,
    edgeColor: COLORS.PRI_GRAY_300,
    selectedEdgeColor: COLORS.SEC_BLUE_LIGHT_700,
  },
};

export const ICON_SIZE = 20;
export const ICON_X = 12;
export const ICON_Y = 12;

export const ICONS_BY_TYPE = {
  source_table: (color: string) => (
    <SourceTableIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  select_columns: (color: string) => (
    <PickColumnsIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  union: (color: string) => <UnionIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />,
  join: (color: string) => <JoinIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />,
  where: (color: string) => <WhereIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />,
  group_by: (color: string) => <GroupByIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />,
  order_by: (color: string) => <OrderByIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />,
};

export const getIcon = (vertex: FlowchartVertex, color: string) => {
  const { type } = vertex;
  const myTable = (vertex as SourceTable).table || null;
  if (type === 'source_table' && myTable !== null) {
    return <TableIcon table={myTable} size={ICON_SIZE} variant="dullAction" />;
  }
  return ICONS_BY_TYPE[type](color);
};

export const getWidth = (vertex: FlowchartVertex): number => {
  if (vertex.type === 'source_table') {
    return VERTEX_TABLE_WIDTH;
  } else {
    return VERTEX_OPERATION_WIDTH;
  }
};

/** Stylesheet for Cytoscape component */
const stylesheet = [
  {
    selector: 'core',
    style: {
      'active-bg-size': 12,
    },
  },
  {
    selector: 'node',
    style: {
      shape: 'rectangle',
      backgroundOpacity: 0,
      width: (ele: any) => getWidth(ele.data().vertex),
      height: (ele: any) => ele.data().vertexHeight || VERTEX_HEIGHT,
    },
  },
  {
    selector: 'edge',
    style: {
      width: (ele: any) => ele.data().width,
      sourceEndpoint: (ele: any) => ele.data().sourceEndpoint,
      targetEndpoint: (ele: any) => ele.data().targetEndpoint,
      lineColor: (ele: any) => ele.data().color,

      arrowScale: 0.8,
      targetArrowShape: 'none',
      targetArrowColor: (ele: any) => ele.data().color,

      // curveStyle=taxi does not work with off center sockets because it uses control points on the node's center axis.
      // We'd need to fork taxi or implement our own thing with segment edges.
      // Using bezier while in Alpha demo mode.
      curveStyle: 'bezier',
      // curveStyle: 'taxi',
      // taxiDirection: 'horizontal',
      // taxiTurnMinDistance: '5px',
    },
  },
  {
    // Highlight egdes on hover so it's easier to see which vertices they're connected to
    selector: 'edge.hovered',
    style: {
      lineColor: COLORS.SEC_BLUE_LIGHT_700,
      targetArrowColor: COLORS.SEC_BLUE_LIGHT_700,
      zIndex: 10,
    },
  },
];

export default stylesheet;
