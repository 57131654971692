/**
 * Methods to convert SavedFlowchartQueryModels to FlowchartQueryModels and visa versa.
 * This file should be easy to write unit tests on.
 */
import { cloneDeep } from 'lodash';

import { ColumnsByTableID } from 'api/columnAPI';
import { AggTable } from 'api/tableAPI';

import {
  BiParentVertex,
  ColumnValue,
  Edge,
  FlowchartQueryModel,
  FlowchartVertex,
  GroupBy,
  Join,
  MonoParentVertex,
  OrderBy,
  SelectColumns,
  SourceTable,
  Union,
} from './FlowchartQueryModel';
import {
  SavedFlowchartQueryModel,
  SavedVertex,
  SavedSourceTable,
  SavedJoin,
  SavedSelectColumns,
  SavedColumnValue,
  SavedGroupBy,
  SavedOrderBy,
} from './SavedFlowchartQueryModel';

function savedColumnValuesToColumnValues(
  savedColumnValues: SavedColumnValue[] | undefined,
  tablesByID: Record<string, AggTable>,
  columnsByTableID: ColumnsByTableID,
): ColumnValue[] | undefined {
  if (savedColumnValues === undefined) {
    return undefined;
  }
  return savedColumnValues.map((s) => {
    const columnValue: ColumnValue = {
      id: s.id,
      value: s.value,
      picked: s.picked,
    };
    const table = tablesByID[s.tableID || ''];
    if (table) {
      columnValue.table = table;
    }
    const column = columnsByTableID[s.tableID || ''].find((c) => c.name === s.value);
    if (column) {
      columnValue.column = column;
    }
    if (s.alias) {
      columnValue.alias = s.alias;
    }
    return columnValue;
  });
}

function columnValuesToSavedColumnValues(
  columnValues: ColumnValue[] | undefined,
): SavedColumnValue[] | undefined {
  if (columnValues === undefined) {
    return undefined;
  }
  return columnValues.map((c) => {
    const savedColumnValue: SavedColumnValue = {
      id: c.id,
      value: c.value,
      picked: c.picked,
    };
    if (c.table) {
      savedColumnValue.tableID = c.table.id;
    }
    if (c.alias) {
      savedColumnValue.alias = c.alias;
    }

    return savedColumnValue;
  });
}

export function savedVertexToFlowchartVertex(
  savedVertex: SavedVertex,
  tablesByID: Record<string, AggTable>,
  columnsByTableID: ColumnsByTableID,
): FlowchartVertex {
  const { id, type, position } = savedVertex;

  if (type === 'source_table') {
    const savedTableVertex = savedVertex as SavedSourceTable;
    const tableVertex = new SourceTable(id, position);
    tableVertex.alias = savedTableVertex.alias;
    tableVertex.table = tablesByID[savedTableVertex.tableID || ''] || null;
    tableVertex.columns = columnsByTableID[savedTableVertex.tableID || ''] || [];
    return tableVertex;
  }

  if (type === 'select_columns') {
    const savedSelectVertex = savedVertex as SavedSelectColumns;
    const selectVertex = new SelectColumns(id, position);

    if (savedSelectVertex.selectedColumns) {
      selectVertex.selectedColumns = savedColumnValuesToColumnValues(
        savedSelectVertex.selectedColumns,
        tablesByID,
        columnsByTableID,
      );
    }
    return selectVertex;
  }

  if (type === 'join') {
    const savedJoinVertex = savedVertex as SavedJoin;
    const joinVertex = new Join(id, position);
    joinVertex.joinType = savedJoinVertex.joinType;
    joinVertex.leftColumn = savedJoinVertex.leftColumn;
    joinVertex.rightColumn = savedJoinVertex.rightColumn;
    if (savedJoinVertex.selectedColumns) {
      joinVertex.selectedColumns = savedColumnValuesToColumnValues(
        savedJoinVertex.selectedColumns,
        tablesByID,
        columnsByTableID,
      );
    }
    return joinVertex;
  }

  if (type === 'union') {
    const unionVertex = new Union(id, position);
    return unionVertex;
  }

  if (type === 'group_by') {
    const savedGroupByVertex = savedVertex as SavedGroupBy;
    const groupByVertex = new GroupBy(id, position);
    groupByVertex.groupBy = savedGroupByVertex.groupBy;
    return groupByVertex;
  }

  if (type === 'order_by') {
    const savedOrderByVertex = savedVertex as SavedOrderBy;
    const orderByVertex = new OrderBy(id, position);
    orderByVertex.orderBy = savedOrderByVertex.orderBy;
    return orderByVertex;
  }

  // Base case that should never happen.
  // This just makes TS happy.
  const flowchartVertex = new FlowchartVertex(id, position);
  return flowchartVertex;
}

export function linkVertices(vertices: FlowchartVertex[], edges: Edge[]) {
  vertices.forEach((vert) => {
    const { id, type } = vert;

    // Try to link the single child of this vertex.
    vert.childID = null;
    const childEdge = edges.find((e) => e.sourceID === id);
    if (childEdge) {
      const child = vertices.find((v) => v.id === childEdge.destinationID);
      vert.childID = child?.id || null;
    }

    // Source tables do not have parents
    if (type === 'source_table') {
      return;
    }

    // Try to link the two parents of a BiParentVertex
    if (vert.isBiParent()) {
      const biParentVertex = vert as BiParentVertex;
      biParentVertex.leftParentID = null;
      biParentVertex.rightParentID = null;

      // Find left parent
      const leftEdge = edges.find((e) => e.destinationID === id && e.destinationSocket === 'left');
      if (leftEdge) {
        const leftParent = vertices.find((v) => v.id === leftEdge.sourceID);
        biParentVertex.leftParentID = leftParent?.id || null;
      }

      // Find right parent
      const rightEdge = edges.find((e) => e.destinationID === id && e.destinationSocket === 'right');
      if (rightEdge) {
        const rightParent = vertices.find((v) => v.id === rightEdge.sourceID);
        biParentVertex.rightParentID = rightParent?.id || null;
      }
    }
    // Try to link the single parent of a MonoParentVertex
    else if (vert.isMonoParent()) {
      const monoParentVertex = vert as MonoParentVertex;
      monoParentVertex.singleParentID = null;

      // Find single parent
      const singleEdge = edges.find((e) => e.destinationID === id && e.destinationSocket === 'single');
      if (singleEdge) {
        const singleParent = vertices.find((v) => v.id === singleEdge.sourceID);
        monoParentVertex.singleParentID = singleParent?.id || null;
      }
    }
  });
}

export function savedModelToFlowchartModel(
  savedFlowchartModel: SavedFlowchartQueryModel,
  tablesByID: Record<string, AggTable>,
  columnsByTableID: ColumnsByTableID,
): FlowchartQueryModel {
  const edges: Edge[] = cloneDeep(savedFlowchartModel.edges);
  const vertices = savedFlowchartModel.vertices.map((s) =>
    savedVertexToFlowchartVertex(s, tablesByID, columnsByTableID),
  );
  linkVertices(vertices, edges);
  const flowchartModel: FlowchartQueryModel = {
    vertices,
    edges,
  };

  return flowchartModel;
}

export function flowchartVertexToSavedVertex(flowchartVertex: FlowchartVertex): SavedVertex {
  const { id, type, position } = flowchartVertex;
  const savedVertex: SavedVertex = {
    id,
    type,
    position,
  };

  if (type === 'source_table') {
    const tableVertex = flowchartVertex as SourceTable;
    const savedTableVertex: SavedSourceTable = {
      ...savedVertex,
      tableID: tableVertex.table?.id || null,
      alias: tableVertex.alias,
    };
    return savedTableVertex;
  }
  if (type === 'select_columns') {
    const selectVertex = flowchartVertex as SelectColumns;
    const savedSelectVertex: SavedSelectColumns = {
      ...savedVertex,
    };
    if (selectVertex.selectedColumns) {
      savedSelectVertex.selectedColumns = columnValuesToSavedColumnValues(selectVertex.selectedColumns);
    }
    return savedSelectVertex;
  }

  if (type === 'join') {
    const joinVertex = flowchartVertex as Join;
    const savedJoinVertex: SavedJoin = {
      ...savedVertex,
      joinType: joinVertex.joinType,
      leftColumn: joinVertex.leftColumn,
      rightColumn: joinVertex.rightColumn,
    };
    if (joinVertex.selectedColumns) {
      savedJoinVertex.selectedColumns = columnValuesToSavedColumnValues(joinVertex.selectedColumns);
    }
    return savedJoinVertex;
  }

  if (type === 'union') {
    // Nothing to set at present
    return savedVertex;
  }

  if (type === 'group_by') {
    const groupByVertex = flowchartVertex as GroupBy;
    const savedGroupByVertex: SavedGroupBy = {
      ...savedVertex,
      groupBy: groupByVertex.groupBy,
    };
    return savedGroupByVertex;
  }

  if (type === 'order_by') {
    const orderByByVertex = flowchartVertex as OrderBy;
    const savedOrderByVertex: SavedOrderBy = {
      ...savedVertex,
      orderBy: orderByByVertex.orderBy,
    };
    return savedOrderByVertex;
  }

  return savedVertex;
}

export function flowchartModelToSavedModel(
  flowchartModel: FlowchartQueryModel,
): SavedFlowchartQueryModel {
  const edges: Edge[] = cloneDeep(flowchartModel.edges);
  const vertices = flowchartModel.vertices.map((s) => flowchartVertexToSavedVertex(s));
  const savedModel: SavedFlowchartQueryModel = {
    vertices,
    edges,
  };

  return savedModel;
}
