import React from 'react';

import {
  Asterisk,
  ClockFill,
  StarFill,
  Diagram3Fill,
  CameraFill,
  FiletypeCsv,
  InfoCircle,
} from 'react-bootstrap-icons';

import ConnectorIcon, {
  ConnectorIconProps,
} from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import {
  MOZART_ALL,
  MOZART_RECENT,
  MOZART_FAVORITES,
  MOZART_TRANSFORMS,
  MOZART_SNAPSHOTS,
  VirtualSchemaType,
} from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';

import DbtIcon from '../DbtIcon/DbtIcon';
import { IconSize } from '../ImgIcon/ImgIcon';

export type ConnectorIconType = Omit<ConnectorIconProps, 'size'>;

export type SchemaIconType =
  // UNMANAGED_SCHEMA will be a ConnectorIconType of some sort so they are excluded here.
  | Exclude<VirtualSchemaType, 'UNMANAGED_SCHEMA'>
  | 'dbt'
  | 'csv_upload'
  | 'info_schema'
  | ConnectorIconType;

/*
Makes a schema icon as the type specified or makes the schema of the connector type specified.
*/
export interface SchemaIconProps {
  type: SchemaIconType;
  size: IconSize;
  variant?: 'teal' | 'dullAction';
}
export default function SchemaIcon(props: SchemaIconProps) {
  const { type, size, variant = 'teal' } = props;

  const color = variant === 'teal' ? 'var(--teal)' : 'var(--sec-blue-gray-500)';
  if (type === MOZART_ALL) {
    return <Asterisk size={size} color={color} />;
  } else if (type === MOZART_RECENT) {
    return <ClockFill size={size} color={color} />;
  } else if (type === MOZART_FAVORITES) {
    return <StarFill size={size} color={color} />;
  } else if (type === 'dbt') {
    return <DbtIcon size={size} />;
  } else if (type === MOZART_TRANSFORMS) {
    return <Diagram3Fill size={size} color={color} transform="rotate(90 0 0)" />;
  } else if (type === MOZART_SNAPSHOTS) {
    return <CameraFill size={size} color={color} />;
  } else if (type === 'csv_upload') {
    return <FiletypeCsv size={size} color={color} />;
  } else if (type === 'info_schema') {
    return <InfoCircle size={size} color={color} />;
  } else {
    // The icon is connector or an unamanged schema without a connector.
    // Program this defensively incase there is a Fivetran connector not in the ConnectorRegistry,
    // a schema created by an unknown actor, or some other unknown.
    let service = '';
    if (typeof type === 'object') {
      service = type.service || '';
    }
    return <ConnectorIcon service={service} size={size} />;
  }
}
