/**
 * This file converts FlowchartVertices into FlowchartExpressions.
 * FlowchartExpressions are spiritually similar to SQLGlotExpressions,
 * except that their properties are UI objects.
 * The SQLGlotExpressions are then submitted to the API for conversion to SQL.
 */
import {
  BiParentVertex,
  FlowchartQueryModel,
  FlowchartVertex,
  getVertex,
  Join,
  MonoParentVertex,
  SelectColumns,
  SourceTable,
} from '../model/FlowchartQueryModel';

export interface FlowchartExpression {
  select?: SelectColumns;
  from?: SourceTable;
  // TODO: Maybe refactor to accept an array of joins?
  join?: Join;
  // TODO: Add more node types.
}

// FOR THOUGHT:
// This file is doing a lot of per vertex validation that might be able to be
// refactored and repurposed for doing vertex validation errors in the UI.
// That might even be a better way to address this problem.

/**
 * @param vertex The vertex to start building FlowchartExpression from.
 * 1. A FlowchartExpression has many optional properties.
 * 2. This method recursively goes up the tree of ancestors until it encounters
 *    some scenario it cannot handle. If that happens it throws an exception.
 *    Note, the UI should not let the user construct a scenario this function cannot handle.
 *
 * @returns The FlowchartExpression represented by the given vertex.
 */
export function buildFlowchartExpression(
  fqm: FlowchartQueryModel,
  vertex: FlowchartVertex,
  // This is a recursive method.
  // Programmers should leave `expressionSoFar` undefined.
  // This gets set by recursions.
  expressionSoFar: FlowchartExpression = {},
): FlowchartExpression {
  /*******************************************************************************
   * Base Case
   ******************************************************************************/
  if (vertex.type === 'source_table') {
    const sourceTable = vertex as SourceTable;
    if (sourceTable.table === null) {
      throw new Error(ERROR_SET_SOURCE_TABLE);
    } else if (expressionSoFar.join === undefined) {
      expressionSoFar.from = sourceTable;
      return expressionSoFar;
    }
  }

  /*******************************************************************************
   * Set Current Vertex First
   ******************************************************************************/
  if (vertex.type === 'select_columns') {
    const selectColumns = vertex as SelectColumns;
    if (selectColumns.selectedColumns) {
      expressionSoFar.select = selectColumns;
    }
  } else if (vertex.type === 'join') {
    const join = vertex as Join;
    if (expressionSoFar.from !== undefined || expressionSoFar.join !== undefined) {
      throw new Error(ERROR_NESTED_JOINS_NOT_SUPPORTED);
    }
    if (join.leftColumn === '') {
      throw new Error(ERROR_SET_LEFT_COLUMN);
    }
    if (join.rightColumn === '') {
      throw new Error(ERROR_SET_RIGHT_COLUMN);
    }
    expressionSoFar.join = join;
  }

  // TODO: Add support for more vertex types

  /*******************************************************************************
   * Recurse Ancestors
   ******************************************************************************/
  if (vertex.isMonoParent()) {
    const monoParent = vertex as MonoParentVertex;
    if (monoParent.singleParentID === null) {
      throw new Error(ERROR_DISCONNECTED_SOCKET);
    }
    const singleParent = getVertex(fqm, monoParent.singleParentID);
    expressionSoFar = buildFlowchartExpression(fqm, singleParent, expressionSoFar);
  }

  if (vertex.isBiParent()) {
    const biParent = vertex as BiParentVertex;
    if (biParent.leftParentID === null) {
      throw new Error(ERROR_DISCONNECTED_SOCKET);
    }

    if (biParent.rightParentID === null) {
      throw new Error(ERROR_DISCONNECTED_SOCKET);
    }
    const leftParent = getVertex(fqm, biParent.leftParentID);
    const rightParent = getVertex(fqm, biParent.rightParentID);
    expressionSoFar = buildFlowchartExpression(fqm, leftParent, expressionSoFar);
    expressionSoFar = buildFlowchartExpression(fqm, rightParent, expressionSoFar);
  }

  return expressionSoFar;
}

export const ERROR_SET_SOURCE_TABLE = 'You must set the table on your source table.';
export const ERROR_DISCONNECTED_SOCKET = 'You must connect every parent socket.';
export const ERROR_NESTED_JOINS_NOT_SUPPORTED = 'Nested joins not supported yet.';
export const ERROR_SET_LEFT_COLUMN = 'You must set the left column of a join.';
export const ERROR_SET_RIGHT_COLUMN = 'You must set the right column of a join.';
