import React, { useContext, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import API from 'api/API';
import { DbtRunConfig, cleanDbtRepository } from 'api/dbtAPI';
import ObjectNotificationSettings from 'components/business_logic/ObjectNotificationSettings/ObjectNotificationSettings';
import Button from 'components/inputs/basic/Button/Button';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import DateHover from 'components/widgets/time/DateHover/DateHover';
import { useUserProfile } from 'context/AuthContext';
import { displaySchedule } from 'model_helpers/dbtRunConfigHelper';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { warehouseSearch } from 'utils/PathMaker';

import RunConfigScheduleWidget from './RunConfigScheduleWidget';

interface ViewModeProps {
  runConfig: DbtRunConfig;
  setEditingConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewMode = (props: ViewModeProps) => {
  const { runConfig, setEditingConfiguration } = props;
  const {
    created_at,
    updated_at,
    commands,
    target_schema,
    dbt_repository,
    dbt_version,
    use_custom_git_branch,
    custom_git_branch,
  } = runConfig;
  const { displayName: repoDisplayName, link: repoLink } = cleanDbtRepository(dbt_repository);

  const history = useHistory();
  const { userProfile } = useUserProfile();
  const { company_role } = userProfile;

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [savingSubscription, setSavingSubscription] = useState(false);
  const { connectorsByID, removeDbtRunConfig, updateDbtRunConfigs } = useContext(TableModelsContext);

  const handleDeleteConfig = (dbtRunConfiguration: DbtRunConfig) => {
    setDeleting(true);
    const api = new API();
    api.delete(`api/dbt_run_configurations/${dbtRunConfiguration.id}`, {}).then(() => {
      analytics.track('ShowDBTRunConfigSettingsTabViewMode DeleteRunConfig');
      removeDbtRunConfig(dbtRunConfiguration);
      history.push('/dbt');
      // TODO: Do we need to display an error if this fails?
    });
  };

  const handleOpenEditMode = () => {
    setEditingConfiguration(true);
    analytics.track('ShowDBTRunConfigSettingsTabViewMode OpenEditMode');
  };

  const handleOpenConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(true);
    analytics.track('ShowDBTRunConfigSettingsTabViewMode OpenConfirmDeleteModal');
  };

  const handleCancelConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    analytics.track('ShowDBTRunConfigSettingsTabViewMode CancelConfirmDeleteModal');
  };

  const isSubscribed =
    !!runConfig.object_email_subscriptions.length &&
    !runConfig.object_email_subscriptions[0].is_opted_out;

  const handleToggleSubscription = () => {
    setSavingSubscription(true);
    // is_opted_out is the opposite of subscribed and we are flipping it, so we can just set is_opted_out to whatever subscribed used to be.
    const api = new API();
    api
      .patch(`api/dbt_run_configurations/${runConfig.id}/subscription`, { is_opted_out: isSubscribed })
      .then(() => {
        updateDbtRunConfigs([
          { ...runConfig, object_email_subscriptions: [{ is_opted_out: isSubscribed }] },
        ]);
      })
      .finally(() => {
        setSavingSubscription(false);
      });
  };

  let displayedSchedule = displaySchedule(runConfig);
  if (displayedSchedule.includes('After All')) {
    displayedSchedule += ':';
  }

  return (
    <TabLayout loading={false} error={''}>
      <div className="w-full grid grid-cols-[max-content_minmax(0,1fr)] gap-x-5 gap-y-4">
        <SavedValue
          label="Git Repo"
          value={
            repoLink ? (
              <div className="flex items-center">
                <a
                  href={repoLink}
                  target="_blank"
                  rel="noreferrer"
                  data-track="ShowDBTRunConfigSettingsTabViewMode LinkToGitRepo"
                  className="hover:underline hover:text-sec-blue-gray-500"
                >
                  {repoDisplayName}
                </a>
                {dbt_repository.git_repository_owner === 'MozartData' && (
                  <InfoIcon
                    placement="top"
                    content={
                      <div
                        style={{ whiteSpace: 'pre-line' }}
                      >{`We set up a dbt repo to get you started.\n\nWe could transfer this repo to your own GitHub organization in a few clicks if you want. Just ask!`}</div>
                    }
                    containerClass="ml-2"
                    popoverProps={{ style: { maxWidth: '550px', fontSize: '1rem' } }}
                  />
                )}
              </div>
            ) : (
              repoDisplayName
            )
          }
        />
        <SavedValue
          label="Git Branch"
          value={
            use_custom_git_branch && custom_git_branch !== null ? (
              custom_git_branch
            ) : (
              <span className="text-pri-gray-400">(Default)</span>
            )
          }
        />
        <SavedValue
          label="Target Schema"
          value={
            <Link
              to={warehouseSearch(`s:${target_schema}`)}
              target="_blank"
              data-track="ShowDBTRunConfigSettingsTabViewMode LinkToWarehouseSearch"
              className="hover:underline hover:text-sec-blue-gray-500"
            >
              {target_schema}
            </Link>
          }
        />
        <SavedValue label="dbt Version" value={dbt_version} />
        <SavedValue
          label="dbt Commands"
          value={
            <div className="w-full overflow-auto">
              {commands.map((c, i) => (
                <code key={c} className="mt-1:not(:first-child) block whitespace-nowrap">
                  {c}
                </code>
              ))}
            </div>
          }
        />
        <SavedValue
          label="Schedule"
          value={<RunConfigScheduleWidget runConfig={runConfig} connectorsByID={connectorsByID} />}
        />
        <SavedValue label="Created At" value={<DateHover date={created_at} />} />
        <SavedValue label="Updated At" value={<DateHover date={updated_at} />} />
      </div>
      <div className="mt-6">
        {company_role !== 'viewer' && (
          <>
            <Button size="small" variant="darkDullAction" onClick={handleOpenEditMode}>
              Edit
            </Button>
            <Button
              size="small"
              variant="darkDanger"
              onClick={handleOpenConfirmDeleteModal}
              className="ml-2"
            >
              Delete
            </Button>
          </>
        )}
        <div className="w-full h-px bg-gray-200 my-4" />
        <ObjectNotificationSettings
          isSubscribed={isSubscribed}
          savingSubscription={savingSubscription}
          handleToggleSubscription={handleToggleSubscription}
          currentStateExplanation={`You are ${
            isSubscribed ? 'subscribed' : 'not subscribed'
          } to failure emails for this dbt job.`}
        />
      </div>
      {showConfirmDeleteModal && (
        <ConfirmDeleteModal
          header="Delete Job"
          confirmText="Delete"
          enableConfirm={true}
          deleting={deleting}
          onCancel={handleCancelConfirmDeleteModal}
          onConfirm={() => handleDeleteConfig(runConfig)}
        >
          <div className="w-[500px] p-4">
            Are you sure you want to delete this job, <code>{runConfig.name}</code>? It will also delete
            the run history and logs, but it will not automatically delete tables created by this job.
          </div>
        </ConfirmDeleteModal>
      )}
    </TabLayout>
  );
};

interface SavedValueProps {
  label: string;
  value: React.ReactNode;
}

const SavedValue = (props: SavedValueProps) => {
  const { label, value } = props;
  return (
    <>
      <div className="font-medium">{label}</div>
      <div>{value}</div>
    </>
  );
};

export default ViewMode;
