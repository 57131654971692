import { Formik } from 'formik';
import * as yup from 'yup';

import { CompanyRole } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import ListboxFormikGroup, {
  ListboxOption,
} from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { GenerateSnowflakeUserPostData } from './GenerateSnowflakeUser';

const formSchema = yup.object({
  username: yup.string().trim().max(255, 'Username cannot be longer than 255 characters'),
  password: yup.string().trim().max(255, 'Password cannot be longer than 255 characters'),
  role: yup.string(),
  first_name: yup.string().trim().max(255, 'First name cannot be longer than 255 characters'),
  last_name: yup.string().trim().max(255, 'Last name cannot be longer than 255 characters'),
  email: yup.string().email('Invalid email').trim(),
});

interface GenerateSnowflakeUserModalProps {
  initialValues: GenerateSnowflakeUserPostData;
  companyRole: CompanyRole;
  snowflakeRoleOptions: ListboxOption[];
  snowflakeRoleError: string;
  snowflakeRoleLoading: boolean;
  saving: boolean;
  error: string;
  onGenerateUser: (values: GenerateSnowflakeUserPostData) => void;
  onClose: () => void;
}

const GenerateSnowflakeUserModal = (props: GenerateSnowflakeUserModalProps) => {
  const {
    initialValues,
    companyRole,
    snowflakeRoleOptions,
    snowflakeRoleError,
    snowflakeRoleLoading,
    saving,
    error,
    onGenerateUser,
    onClose,
  } = props;

  return (
    <Modal
      onClose={onClose}
      header="Generate Snowflake User"
      cancelButton={true}
      containerClass="w-[500px]"
    >
      <div className="flex flex-col h-full pt-2">
        {error && (
          <Alert className="mx-4 mb-2" variant="error">
            {error}
          </Alert>
        )}
        <Formik validationSchema={formSchema} onSubmit={onGenerateUser} initialValues={initialValues}>
          {({ handleSubmit }) => {
            return (
              <form noValidate onSubmit={handleSubmit} className="flex flex-col h-full w-full">
                <div className="px-4 mb-2">
                  <TextFormikGroup name="username" label="Username" />
                  {companyRole === 'admin' && (
                    <ListboxFormikGroup
                      label="Snowflake Role"
                      name="role"
                      options={snowflakeRoleOptions}
                      groupClass="mt-4"
                      optionsMaxHeightClass="max-h-[250px]"
                      error={snowflakeRoleError}
                      spinning={snowflakeRoleLoading}
                      postLabelElement={
                        <InfoIcon
                          content="Select a Snowflake role to assign to this user."
                          containerClass="ml-1"
                          popoverProps={{ style: { maxWidth: '700px' } }}
                        />
                      }
                    />
                  )}
                  {companyRole !== 'viewer' && (
                    <>
                      <TextFormikGroup
                        name="first_name"
                        label="First Name (Optional)"
                        groupClass="mt-4"
                      />
                      <TextFormikGroup name="last_name" label="Last Name (Optional)" groupClass="mt-4" />
                      <TextFormikGroup
                        name="email"
                        label="Email (Optional)"
                        groupClass="mt-4"
                        type="email"
                      />
                    </>
                  )}
                  <Button type="submit" variant="lightAction" spinning={saving} className="mt-4 mb-2">
                    Save
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default GenerateSnowflakeUserModal;
