/*
Creates scrollable area for table/folder/tag list content.

ATTENTION:
In order to prevent excessive rerenders the caller of this component should:
  1. Wrap the `renderedTables` prop in a React.memo().
  2. Wrap the `getHoveredTable` prop in a useCallback().
  3. Wrap the `renderTableHover` prop in a useCallback().
*/
import React, { useMemo } from 'react';

import { AggTable } from 'api/APITypes';
import useHoveredIndex from 'hooks/useHoveredIndex';

import OverlayArea, { useOverlayArea } from '../../layouts/parts/OverlayArea/OverlayArea';

import { ROW_HEIGHT } from './TableRow/TableRow';

export interface PassedByHoveredIndexWrapperUser {
  maxHeight: string;
  selectedTable: AggTable | null;
  renderTableHover(
    hoveredIndex: number,
    hoveredTable: AggTable,
    selectedTable: AggTable | null,
    overlayRight: number,
  ): React.ReactNode;
}

interface SetByHoveredIndexWrapperUser {
  getHoveredTable(hoveredIndex: number): AggTable | null;
  renderedTables: React.ReactNode;
}

interface HoveredIndexWrapperProps
  extends PassedByHoveredIndexWrapperUser,
    SetByHoveredIndexWrapperUser {}

const HoveredIndexWrapper = React.memo((props: HoveredIndexWrapperProps) => {
  const { maxHeight, selectedTable, getHoveredTable, renderedTables, renderTableHover } = props;
  const { scrollRef, innerRef, overlayRight } = useOverlayArea();
  const hoveredIndex = useHoveredIndex(innerRef, scrollRef, ROW_HEIGHT);

  const hoveredTable = useMemo(() => getHoveredTable(hoveredIndex), [getHoveredTable, hoveredIndex]);

  return (
    <div className="tt-table-name-list">
      <OverlayArea
        className="tt-table-list-scrolling-area"
        style={{ maxHeight }}
        scrollRef={scrollRef}
        innerRef={innerRef}
      >
        {renderedTables}

        {/* 
        Known Bug Tradeoff:
        Lets rebrand this as a "feature".
        Updated 08/11/2022.
        We thought we would be "smart" and render the table hover buttons on top
        of the rows so that we wouldn't have to rerender the entire list of thousands of rows 
        to update the hover state of two rows(unhovering the first and start hovering the second).
        This is great for perfomance, but if the user hovers directly over the buttons the 
        table row loses hover state because the buttons are on a different stacking context
        and therefore the hover doesn't pass through the buttons to the underlying table row.
        To the best of our knowledge, the only way to fix this and maintain good performance is to
        put the buttons in the table row so they have the same stacking context and to have
        the table rows subscribe and unsubscribe to a hovered index event listener on mount and unmount.
        This is a lot of work and we are not doing that right now to fix a bug we doubt will bother people.
        Most people probably won't even notice.
        */}
        {hoveredIndex > -1 &&
          hoveredTable &&
          renderTableHover(hoveredIndex, hoveredTable, selectedTable, overlayRight)}
      </OverlayArea>
    </div>
  );
});

export default HoveredIndexWrapper;
