import React from 'react';

import { Formik, Form } from 'formik';

import Button from 'components/inputs/basic/Button/Button';
import ComboboxFormikGroup from 'components/inputs/formik_group/ComboboxFormikGroup/ComboboxFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

import { CreateAlertSource } from './useCreateDataAlerts';

export interface CreateDataAlertsModalProps {
  tableOptions: string[];
  filterOptions: (filter: string) => void;
  onClose: (eventLocation: CreateAlertSource) => void;
  onSubmit: (value: string, eventLocation: CreateAlertSource) => void;
}

type Values = {
  tableName: string;
};

const CreateDataAlertsModal: React.FC<CreateDataAlertsModalProps> = ({
  tableOptions,
  filterOptions,
  onClose,
  onSubmit,
}) => {
  const handleSubmit = (values: Values) => {
    // TODO(create-data-alerts): Determine if it's useful to log the initial event location for
    // selecting a table from the create modal even if it all happens in the list page
    onSubmit(values.tableName, 'DataAlertsPage');
  };

  const handleCloseModal = () => {
    // TODO(create-data-alerts): Determine if it's useful to log the initial event location for
    // closing the create modal even if it all happens in the list page
    onClose('DataAlertsPage');
  };

  const handleTableNameFieldChange = (
    newVal: string,
    setFieldValue: (name: string, value: string) => void,
  ) => {
    filterOptions(newVal);
    setFieldValue('tableName', newVal);
  };

  const footer = (
    <div>
      <Button type="submit" variant="lightAction">
        Continue
      </Button>
      <Button onClick={handleCloseModal} variant="lightDanger" className="ml-2">
        Cancel
      </Button>
    </div>
  );

  return (
    <Formik initialValues={{ tableName: '' }} onSubmit={handleSubmit}>
      {({ setFieldValue, isSubmitting }) => (
        <Form>
          <Modal header="Create Alert" cancelButton footer={footer} onClose={handleCloseModal}>
            <div className="w-[500px] p-4">
              <p>Select a table you want to create an alert for</p>
              {/* 
                TODO(create-data-alerts): There are UI height issues with this field in a modal.
                Figure out how to fix menu to pop over the modal footer instead of increasing the body
                height or use a different component/create a new component for this field.
              */}
              <ComboboxFormikGroup
                disabled={isSubmitting}
                options={tableOptions}
                name="tableName"
                label="Table Name"
                groupClass="mt-4"
                filter
                shouldShowCustomOption={false}
                onChange={(newVal) => handleTableNameFieldChange(newVal, setFieldValue)}
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default CreateDataAlertsModal;
