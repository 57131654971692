import { useCallback, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';
import * as yup from 'yup';

import { typedAssign } from 'utils/object';
import { getValidationErrorsSync } from 'utils/Validators';

import { EditVertex } from '../../FlowchartEditor';
import {
  ColumnValue,
  FlowchartQueryModel,
  FlowchartVertex,
  Join,
} from '../../model/FlowchartQueryModel';
import {
  aliasDuplicateColumns,
  getPickedAvailableColumnValues,
} from '../../query_builder/availableColumns';

import { validateColumnSelections } from '../select_columns/SelectColumnsModal/ColumnSelector/useColumnSelector';

import { JoinModalProps, SavableJoinProps } from './JoinModal';

export interface JoinState extends EditVertex<Join, JoinModalProps> {}

export default function useJoin(
  fqm: FlowchartQueryModel,
  updateVertex: (vertex: FlowchartVertex) => void,
  handleSelectVertex: (vertex: FlowchartVertex) => void,
): JoinState {
  const [vertex, setVertex] = useState<Join | null>(null);

  const onCancel = useCallback(() => {
    setVertex(null);
  }, []);

  const onSave = useCallback(
    (savableProps: SavableJoinProps) => {
      if (vertex) {
        const updatedVertex = cloneDeep(vertex);
        typedAssign(updatedVertex, savableProps);

        updateVertex(updatedVertex);
        handleSelectVertex(updatedVertex);
        setVertex(null);
      }
    },
    [vertex, updateVertex, handleSelectVertex],
  );

  const onEditVertex = useCallback((vertex: Join) => {
    setVertex(vertex);
  }, []);

  const leftAvailableColumnValues = useMemo(
    () => getPickedAvailableColumnValues(fqm, vertex?.leftParentID || null),
    [fqm, vertex?.leftParentID],
  );
  const rightAvailableColumnValues = useMemo(
    () => getPickedAvailableColumnValues(fqm, vertex?.rightParentID || null),
    [fqm, vertex?.rightParentID],
  );

  const availableColumnValues = useMemo(
    () => [...leftAvailableColumnValues, ...rightAvailableColumnValues],
    [leftAvailableColumnValues, rightAvailableColumnValues],
  );

  const initialSelectedColumns: ColumnValue[] | undefined = useMemo(() => {
    // If the user previously saved selectedColumns, use that.
    if (vertex?.selectedColumns) {
      return vertex.selectedColumns;
    }

    // If we had to automatically deduplicate a column name,
    // return the deduplicated column list.
    const deduplicatedColumns = aliasDuplicateColumns(availableColumnValues);
    const hasDeduplicate = deduplicatedColumns.some((c) => !!c.alias);
    if (hasDeduplicate) {
      return deduplicatedColumns;
    }

    // Default case
    return undefined;
  }, [vertex?.selectedColumns, availableColumnValues]);

  const modalProps: JoinModalProps | null = useMemo(() => {
    if (vertex) {
      const joinModalProps: JoinModalProps = {
        joinType: vertex.joinType,
        leftColumn: vertex.leftColumn,
        rightColumn: vertex.rightColumn,
        leftJoinOnColumns: leftAvailableColumnValues,
        rightJoinOnColumns: rightAvailableColumnValues,
        availableColumnValues,
        selectedColumnValues: initialSelectedColumns,
        onCancel,
        onSave,
      };

      return joinModalProps;
    }

    return null;
  }, [
    vertex,
    leftAvailableColumnValues,
    rightAvailableColumnValues,
    availableColumnValues,
    initialSelectedColumns,
    onCancel,
    onSave,
  ]);

  const result: JoinState = useMemo(
    () => ({
      modalProps,
      onEditVertex,
    }),
    [modalProps, onEditVertex],
  );

  return result;
}

export const validateJoin = (
  leftColumn: string,
  rightColumn: string,
  currentSelections?: ColumnValue[],
): [Record<string, string>, Record<string, string>, string] => {
  const [aliasErrors, formError] = validateColumnSelections(currentSelections);
  // MAYBE TODO:
  // Check that leftColumn and rightColumn are valid column names
  const schema = yup.object({
    leftColumn: yup.string().required('Required'),
    rightColumn: yup.string().required('Required'),
  });
  const formValues = {
    leftColumn,
    rightColumn,
  };
  const joinErrors = getValidationErrorsSync(schema, formValues);
  return [joinErrors, aliasErrors, formError];
};
