/*
This is the TableExplorer used in the QueryEditor.
It has customization relevant to editing SQL and the QueryEditor screen layout.

Note that it memoizes everything to reduce the number of rerenders.
The TableExplorer component hierarchy is rather expesive.
*/
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { AggTable } from 'api/APITypes';
import { Column } from 'api/columnAPI';
import IconButton from 'components/inputs/basic/Button/IconButton';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';

import CopiedTip from './CopiedTip';
import CopyButton, { useCopyButton } from './CopyButton';
import TableExplorerBase from './TableExplorerBase';
import TableRowHoverOverlay from './TableRow/TableRowHoverOverlay';
import useTableExplorerReducer from './useTableExplorerReducer';

interface TableExplorerProps {
  editMode: boolean;
  selectedTable: AggTable | null;
  tableDraggable: boolean;
  tablesNamesUsedInSql: string[];
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onInsertAtCursor(text: string): void;
  onInsertTableQuery(table: AggTable): void;
  onInsertColumnQuery(fullName: string, column: string): void;
  setShowTableExplorer: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableExplorer = React.memo((props: TableExplorerProps) => {
  const {
    editMode,
    selectedTable,
    tablesNamesUsedInSql,
    onInsertAtCursor,
    onInsertTableQuery,
    onInsertColumnQuery,
    setShowTableExplorer,
  } = props;

  const tableExplorerReduction = useTableExplorerReducer('TableExplorer');

  const {
    loaded,
    tablesPinnedBySqlMap,
    tablesActuallyPinnedMap,
    unfilteredSchemasMap,
    unfilteredTables,
    unfilteredRecentTables,
    unfilteredFavoriteTables,
    unfilteredTagsMap,
    unfilteredTablesInTagsCount,
    viewMode,
    setViewMode,
    handleManuallyPinTable,
    setTableNamesInSql,
  } = tableExplorerReduction;

  /*******************************************************************************
   * Overrides relevant to QueryEditor Layout
   ******************************************************************************/
  useEffect(() => {
    setTableNamesInSql(tablesNamesUsedInSql);
  }, [tablesNamesUsedInSql, setTableNamesInSql]);

  const hasCheckedDefaultToPinned = useRef(false);
  useEffect(() => {
    if (loaded && !hasCheckedDefaultToPinned.current) {
      hasCheckedDefaultToPinned.current = true;

      let reportedViewMode = viewMode;
      if (tablesNamesUsedInSql.length > 0) {
        reportedViewMode = 'pinned';
        setViewMode(reportedViewMode, true);
      }

      analytics.track('TableExplorer LoadedStats', {
        view_mode: reportedViewMode,
        schemas: Object.keys(unfilteredSchemasMap).length,
        tables: unfilteredTables.length,
        pinned: tablesNamesUsedInSql.length,
        recents: unfilteredRecentTables.length,
        favorites: unfilteredFavoriteTables.length,
        tags: Object.keys(unfilteredTagsMap).length,
        tagged_tables: unfilteredTablesInTagsCount,
      });
    }
  }, [loaded, tablesNamesUsedInSql, setViewMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMinimizeTableExplorer = useCallback(() => {
    analytics.track('TableExplorer MinimizeTableExplorer');
    setShowTableExplorer(false);
  }, [setShowTableExplorer]);

  const rightOfSearchWidget = useMemo(
    () => (
      <TooltipTrigger tip="Hide Table Explorer">
        <IconButton
          icon="ArrowBarRight"
          variant="lightDullTransparent"
          size="small"
          onClick={handleMinimizeTableExplorer}
          className="ml-2"
        />
      </TooltipTrigger>
    ),
    [handleMinimizeTableExplorer],
  );

  /*******************************************************************************
   * Overrides relevant to TableRows In TableList(s)
   ******************************************************************************/
  const handleInsertTableName = useCallback(
    (table: AggTable) => {
      analytics.track('TableExplorer InsertTableName');
      return onInsertAtCursor(table.full_name);
    },
    [onInsertAtCursor],
  );

  const handleInsertTableQuery = useCallback(
    (table: AggTable) => {
      analytics.track('TableExplorer InsertTableQuery');
      return onInsertTableQuery(table);
    },
    [onInsertTableQuery],
  );

  const { showCopied, copiedRef, handleCopy } = useCopyButton();
  const handleTableCopy = useCallback(
    (value: string, targetRef: React.MutableRefObject<HTMLButtonElement | null>) => {
      analytics.track('TableExplorer CopyTableName');
      handleCopy(value, targetRef);
    },
    [handleCopy],
  );

  const renderTableHover = useCallback(
    (
      hoveredIndex: number,
      hoveredTable: AggTable,
      selectedTable: AggTable | null,
      overlayRight: number,
    ) => {
      return (
        <TableRowHoverOverlay
          hoveredIndex={hoveredIndex}
          table={hoveredTable}
          selected={hoveredTable.id === selectedTable?.id}
          pinned={!!tablesActuallyPinnedMap[hoveredTable.id]}
          pinnedBySql={!!tablesPinnedBySqlMap[hoveredTable.id]}
          showCopied={showCopied}
          overlayRight={overlayRight}
          editMode={editMode}
          onManuallyPinTable={handleManuallyPinTable}
          onInsertTableQuery={handleInsertTableQuery}
          onCopy={handleTableCopy}
        />
      );
    },
    [
      editMode,
      showCopied,
      tablesActuallyPinnedMap,
      tablesPinnedBySqlMap,
      handleManuallyPinTable,
      handleInsertTableQuery,
      handleTableCopy,
    ],
  );

  /*******************************************************************************
   * Overrides relevant to ColumnRows in TableDetails
   ******************************************************************************/
  const handleInsertColumnName = useCallback(
    (column: Column) => {
      analytics.track('TableDetails InsertColumnName');
      return onInsertAtCursor(column.name);
    },
    [onInsertAtCursor],
  );

  const handleInsertColumnQuery = useCallback(
    (column: string) => {
      if (selectedTable) {
        // On 5/20/2021, this was renamed from 'TableDetails InsertColumn' to 'TableDetails InsertColumnQuery'.
        // At this point, a different 'TableDetails InsertColumn' event was added.
        analytics.track('TableDetails InsertColumnQuery');
        onInsertColumnQuery(selectedTable.full_name, column);
      }
    },
    [selectedTable, onInsertColumnQuery],
  );

  const handleCopyColumn = useCallback(
    (value: string, targetRef: React.MutableRefObject<HTMLButtonElement | null>) => {
      analytics.track('TableDetails CopyColumnName');
      handleCopy(value, targetRef);
    },
    [handleCopy],
  );

  const renderColumnHover = useCallback(
    (column: Column, overlayRight: number) => {
      return (
        <div
          data-disable="disable_dsample"
          className="tt-table-buttons"
          style={{ right: `${overlayRight}px` }}
        >
          {editMode && (
            <TooltipTrigger tip="Query distinct values in editor.">
              <IconButton
                icon="BoxArrowLeft"
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleInsertColumnQuery(column.name);
                }}
                size="small"
                variant="lightDullTransparent"
              />
            </TooltipTrigger>
          )}
          <TooltipTrigger tip="Copy column name." forceShow={showCopied === true ? false : undefined}>
            <CopyButton value={column.name} onCopy={handleCopyColumn} />
          </TooltipTrigger>
        </div>
      );
    },
    [editMode, showCopied, handleInsertColumnQuery, handleCopyColumn],
  );

  return (
    <>
      <TableExplorerBase
        {...props}
        tableExplorerReduction={tableExplorerReduction}
        eventLocation="TableExplorer"
        onDoubleClickTable={handleInsertTableName}
        onDoubleClickColumn={handleInsertColumnName}
        renderTableHover={renderTableHover}
        renderColumnHover={renderColumnHover}
        rightOfSearchWidget={rightOfSearchWidget}
      />
      <CopiedTip show={showCopied} targetRef={copiedRef} />
    </>
  );
});

export default TableExplorer;
