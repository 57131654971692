import React, { useCallback, useMemo } from 'react';

import { MOZART_TRANSFORMS } from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';
import { KeywordLists } from 'utils/TableFilter';

import { pickHighlightFilter } from '../../highlightUtils';

import TableExplorerSchemaExpando from '../expandos/TableExplorerSchemaExpando';

import GenericExpandoList, { GenericExpandoListProps, RenderExpandoProps } from './GenericExpandoList';

interface SchemaExpandoListProps extends Omit<GenericExpandoListProps, 'renderExpando'> {
  filterIncludes: KeywordLists;
  onToggleSchema(schemaKey: string): void;
}

const SchemaExpandoList = (props: SchemaExpandoListProps) => {
  const { onToggleSchema, filterIncludes, ...rest } = props;

  const highlightFilter = useMemo(() => pickHighlightFilter(filterIncludes, 'schema'), [filterIncludes]);

  const renderExpando = useCallback(
    (props: RenderExpandoProps) => {
      const { key, ...rest } = props;
      const hideSchema = key !== MOZART_TRANSFORMS;
      return (
        <TableExplorerSchemaExpando
          {...rest}
          key={key}
          schemaKey={key}
          hideSchema={hideSchema}
          highlightFilter={highlightFilter}
          onToggleSchema={onToggleSchema}
        />
      );
    },
    [onToggleSchema, highlightFilter],
  );

  return <GenericExpandoList {...rest} renderExpando={renderExpando} />;
};

export default SchemaExpandoList;
