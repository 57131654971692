import { useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';
import * as yup from 'yup';

import { forEachDuplicateColumn } from 'components/query/FlowchartEditor/query_builder/availableColumns';
import useMemoObject from 'hooks/useMemoObject';
import { getValidationErrorsSync, valDBIdentifier } from 'utils/Validators';

import { ColumnValue } from '../../../../model/FlowchartQueryModel';

import { ColumnValueID } from './SelectColumnsRow/SelectColumnsRow';

function buildInitialSelections(
  availableColumns: ColumnValue[],
  selectedColumns: ColumnValue[] | undefined,
): ColumnValue[] {
  let initialSelections = cloneDeep(selectedColumns ? selectedColumns : availableColumns);

  // New available columns could have been created since we last saved.
  // Because the initialSelections might be sorted,
  // append these new columns to the end.
  const newColumns = availableColumns.filter((ac) => {
    const found = initialSelections.find((ic) => ic.column?.id === ac.column?.id);
    return !found;
  });
  if (newColumns) {
    initialSelections = [...initialSelections, ...newColumns];
  }
  return initialSelections;
}

export interface UseColumnSelectorState {
  initialSelections: ColumnValue[];
  currentSelections: ColumnValue[];
  aliasErrors: Record<ColumnValueID, string>;
  formError: string;
  setCurrentSelections: React.Dispatch<React.SetStateAction<ColumnValue[]>>;
}

const useColumnSelector = (
  availableColumns: ColumnValue[],
  selectedColumns?: ColumnValue[],
): UseColumnSelectorState => {
  const [initialSelections] = useState<ColumnValue[]>(() =>
    buildInitialSelections(availableColumns, selectedColumns),
  );
  const [currentSelections, setCurrentSelections] = useState<ColumnValue[]>(() =>
    cloneDeep(initialSelections),
  );

  // Validate the form every time current selections is updated
  const [aliasErrors, formError] = useMemo(() => {
    return validateColumnSelections(currentSelections);
  }, [currentSelections]);

  const state = useMemoObject<UseColumnSelectorState>({
    initialSelections,
    currentSelections,
    aliasErrors,
    formError,
    setCurrentSelections,
  });

  return state;
};

export default useColumnSelector;

export const validateColumnSelections = (
  currentSelections?: ColumnValue[],
): [Record<string, string>, string] => {
  if (!currentSelections) {
    return [{}, ''];
  }

  const validatorsByColumn: Record<ColumnValueID, any> = {};
  const valuesByColumn: Record<ColumnValueID, string> = {};

  // Check the format of each alias
  // Only validate non-empty aliases
  currentSelections.forEach((cs) => {
    if (cs.alias) {
      // TODO:
      // This is probably going to need to be adjusted to allow reserved words
      // Good enough for now.
      validatorsByColumn[cs.id] = valDBIdentifier('Alias');
      valuesByColumn[cs.id] = cs.alias;
    }
  });
  const schema = yup.object(validatorsByColumn);
  const aliasErrors = getValidationErrorsSync(schema, valuesByColumn);

  // Check for duplicate column names
  const onDuplicate = (duplicate: ColumnValue, duplicateName: string) => {
    aliasErrors[duplicate.id] = `"${duplicateName}" is already used by another column.`;
  };
  forEachDuplicateColumn(currentSelections, onDuplicate, true);

  let formError = '';
  const hasColumn = currentSelections.some((cs) => cs.picked);
  if (!hasColumn) {
    formError = 'You must select at least one column.';
  }
  return [aliasErrors, formError];
};
