import * as Icons from 'react-bootstrap-icons';

import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';

import { VertexType } from '../../model/FlowchartQueryModel';

import HoverContent from './HoverContent';

export type IconKey = keyof typeof Icons;

export interface ToolbarItemDefinition {
  id: VertexType;
  icon: IconKey;
  title: string;
  description: string;
  img: string;
}

export interface ToolbarItemProps extends ToolbarItemDefinition {
  enabled: boolean;
}

const ToolbarItem = (props: ToolbarItemProps) => {
  const { id, icon, title, description, img, enabled } = props;
  const IconTag = Icons[icon] || Icons['QuestionSquare'];

  const handleDragStart = (vertexType: VertexType, event: React.DragEvent<HTMLSpanElement>) => {
    const encodedData = JSON.stringify({ dropType: 'ToolbarItem', vertexType });
    event.dataTransfer.setData('text/plain', encodedData);
  };

  const handleDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <PopperTrigger
      placement="right"
      triggers="hover"
      delay={{ show: 500, hide: 50 }}
      popoverProps={{ style: { maxWidth: '700px' } }}
      renderPopper={(popperProps) => {
        return (
          <Popover
            content={<HoverContent title={title} description={description} img={img} />}
            {...popperProps}
          />
        );
      }}
    >
      <div
        className="w-[24px]"
        draggable={enabled}
        onDragStartCapture={(e) => handleDragStart(id, e)}
        onDragOverCapture={handleDragOver}
      >
        <IconTag size={24} color={enabled ? 'var(--sec-blue-gray-500)' : 'var(--pri-gray-400)'} />
      </div>
    </PopperTrigger>
  );
};

export default ToolbarItem;
