/**
 * CodeEditor for showing basic snippets of code such as configs, sql snippets, json, xml, etc...
 * This is much simpler than the CodeMirrorSqlEditor that the main UI uses
 * to allow the user to edit transforms.
 **/
import { CSSProperties, useMemo } from 'react';

import { merge } from 'lodash';

import {
  getLanguageExtension,
  guessEditorLanguage,
} from 'components/query/CodeMirrorSqlEditor/CodeMirrorLanguageUtils';
import { DISABLED_THEME_STYLES } from 'components/query/CodeMirrorSqlEditor/useCodeMirrorTheme';
import { clamp } from 'utils/Math';

import CodeMirror, { EditorView } from '@uiw/react-codemirror';

export const PADDING_HEIGHT = 4;
export const ROW_HEIGHT = 16.8;

export function editorHeight(rowCount: number) {
  return rowCount * ROW_HEIGHT + PADDING_HEIGHT * 2;
}

export function clampedEditorHeight(rowCount: number, minRows: number, maxRows: number) {
  return editorHeight(clamp(rowCount, minRows, maxRows));
}

export interface CodeEditorProps {
  value: string;
  height: string;
  minHeight?: string;
  maxHeight?: string;
  mode?: string;
  editable?: boolean;
  disableGutters?: boolean; // Gutters are off by default
  themeOverrides?: object;
  style?: CSSProperties;
  onChange?: (value: string) => void;
}

const CodeEditor = ({
  value,
  height,
  minHeight,
  maxHeight,
  mode,
  editable = false,
  disableGutters,
  themeOverrides,
  style,
  onChange,
}: CodeEditorProps) => {
  const mergedTheme = useMemo(() => {
    let newOverrides = {
      ...DISABLED_THEME_STYLES,
    };
    if (disableGutters) {
      newOverrides = merge(newOverrides, { '.cm-gutters': { display: 'none' } });
    }
    if (themeOverrides) {
      newOverrides = merge(newOverrides, themeOverrides);
    }
    return EditorView.theme(newOverrides);
  }, [disableGutters, themeOverrides]);

  const languageExtension = getLanguageExtension(mode || guessEditorLanguage(value));

  return (
    <CodeMirror
      value={value}
      height={height}
      style={style}
      minHeight={minHeight}
      maxHeight={maxHeight}
      extensions={[mergedTheme, languageExtension]}
      readOnly={!editable}
      editable={editable}
      autoFocus={false}
      onChange={(editor) => {
        if (onChange) {
          onChange(editor);
        }
      }}
    />
  );
};

export default CodeEditor;
