/**
 * This file calculates which columns can be SELECTed from
 * a given vertex by traversing all of its ancestors for the
 * columns they contribute to this vertex.
 */
import { cloneDeep } from 'lodash';

import { AggTable } from 'api/APITypes';
import { Column } from 'api/columnAPI';

import {
  BiParentVertex,
  MonoParentVertex,
  SourceTable,
  ColumnValue,
  HasSelectColumns,
  FlowchartQueryModel,
  getVertex,
} from '../model/FlowchartQueryModel';

export function sourceTableToColumnValue(sourceTable: SourceTable, column: Column): ColumnValue {
  // This method assumes `.table` is defined.
  // We could throw an exception here but that is overkill.
  const table: AggTable = sourceTable.table as AggTable;
  const columnValue: ColumnValue = {
    // vertex.id + column name guarantees uniqueness.
    // table name is a developer debugging convenience.
    id: `${sourceTable.id}_${table.name}_${column.name}_id`,
    value: column.name,
    picked: true,
    table: table,
    column,
  };
  return columnValue;
}

export function getAvailableColumnValues(
  fqm: FlowchartQueryModel,
  vertexID: string | null,
): ColumnValue[] {
  // Base case: It's empty. Return nothing.
  if (vertexID === null) {
    return [];
  }

  const vertex = getVertex(fqm, vertexID);

  // Base case: It's source table. Return it's columns.
  if (vertex.type === 'source_table') {
    const sourceTable = vertex as SourceTable;
    if (sourceTable.table === null) {
      return [];
    }

    const availableColumnValues: ColumnValue[] = sourceTable.columns.map((c) =>
      sourceTableToColumnValue(sourceTable, c),
    );
    return availableColumnValues;
  }

  // Base case: It picked columns. Return the picked columns.
  const hasSelectColumnsVertex = vertex as HasSelectColumns;
  if (hasSelectColumnsVertex.selectedColumns) {
    return hasSelectColumnsVertex.selectedColumns;
  }

  // TODO: Add another base case for a GroupBy vertex and set columnsAreGrouped.
  //       This might just be another instance of HasSelectColumns.
  // TODO: wildcards, subqueries, table aliases, and other arbitrary SQL
  //       in a SelectColumns would wildly complicate this.
  //       Ignoring this until necessary.

  // At this point, we know the vertex does not define its own list of columns to return.
  // Recurse its ancestors for columns that it passes through.

  if (vertex.isMonoParent()) {
    const monoParent = vertex as MonoParentVertex;
    if (monoParent.singleParentID) {
      return getAvailableColumnValues(fqm, monoParent.singleParentID);
    }
  }

  if (vertex.isBiParent()) {
    const biParent = vertex as BiParentVertex;
    if (biParent.leftParentID && biParent.rightParentID) {
      return [
        ...getAvailableColumnValues(fqm, biParent.leftParentID),
        ...getAvailableColumnValues(fqm, biParent.rightParentID),
      ];
    }
  }

  // The vertex does not have any parents.
  return [];
}

export function getPickedAvailableColumnValues(
  fqm: FlowchartQueryModel,
  vertexID: string | null,
): ColumnValue[] {
  return getAvailableColumnValues(fqm, vertexID).filter((c) => c.picked);
}

export function forEachDuplicateColumn(
  columnValues: ColumnValue[],
  onDuplicate: (duplicate: ColumnValue, duplicateName: string) => void,
  onlyPicked: boolean = false,
): void {
  columnValues.forEach((cv, myIndex) => {
    if (onlyPicked && cv.picked === false) {
      return;
    }

    const myName = cv.alias || cv.value;

    // Search every column before me to see if myName has already been used.
    let imADuplicateOf: ColumnValue | null = null;
    for (let i = 0; i < myIndex; i++) {
      const them = columnValues[i];
      const theirName = them.alias || them.value;
      if (theirName === myName && (onlyPicked === false || them.picked)) {
        imADuplicateOf = them;
        break;
      }
    }
    if (imADuplicateOf) {
      onDuplicate(cv, myName);
    }
  });
}

export function aliasDuplicateColumns(availableColumnValues: ColumnValue[]): ColumnValue[] {
  const clone = cloneDeep(availableColumnValues);
  const onDuplicate = (cv: ColumnValue) => {
    // TODO: When we support calculated fields allow getUniqueAlias to work without table object
    if (cv.table) {
      cv.alias = getUniqueAlias(cv.value, cv.table);
    }
  };
  forEachDuplicateColumn(clone, onDuplicate);
  return clone;
}

export function getUniqueAlias(columnName: string, table: AggTable): string {
  const tableSuffix = `${table.schema}_${table.name}`;
  return `${columnName}__${tableSuffix}`;
}
