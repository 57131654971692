import React, { useCallback } from 'react';

import { AggTable } from 'api/APITypes';

import HoveredIndexWrapper from './HoveredIndexWrapper';
import TableRow from './TableRow/TableRow';

export interface PassedToTableRowProps {
  highlightFilter: string | RegExp;
  onSetTable(table: AggTable): void;
  onDoubleClickTable(table: AggTable): void;
}

interface PassedToInnerTableNameListProps {
  filteredTables: AggTable[];
  selectedTable: AggTable | null;
  tableDraggable: boolean;
  passedToTableRow: PassedToTableRowProps;
}

interface TableNameListProps extends PassedToInnerTableNameListProps {
  maxHeight: string;
  renderTableHover(
    hoveredIndex: number,
    hoveredTable: AggTable,
    selectedTable: AggTable | null,
    overlayRight: number,
  ): React.ReactNode;
}

interface InnerTableNameListProps extends PassedToInnerTableNameListProps {}

const TableNameList = React.memo((props: TableNameListProps) => {
  const { filteredTables, maxHeight, renderTableHover, selectedTable, tableDraggable, ...rest } = props;

  const getHoveredTable = useCallback(
    (hoveredIndex: number) => {
      return filteredTables[hoveredIndex];
    },
    [filteredTables],
  );

  return (
    <HoveredIndexWrapper
      maxHeight={maxHeight}
      renderTableHover={renderTableHover}
      selectedTable={selectedTable}
      getHoveredTable={getHoveredTable}
      renderedTables={
        <InnerTableNameList
          filteredTables={filteredTables}
          selectedTable={selectedTable}
          tableDraggable={tableDraggable}
          {...rest}
        />
      }
    />
  );
});

const InnerTableNameList = React.memo((props: InnerTableNameListProps) => {
  const { filteredTables, selectedTable, tableDraggable, passedToTableRow } = props;

  return (
    <div className="tt-table-list-name-list">
      {filteredTables.map((table: AggTable) => (
        <TableRow
          key={table.id}
          table={table}
          selected={table.id === selectedTable?.id}
          draggable={tableDraggable}
          {...passedToTableRow}
        />
      ))}
    </div>
  );
});

export default TableNameList;
