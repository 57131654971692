import { memo } from 'react';

import Combobox from 'components/inputs/basic/Combobox/Combobox';

import { ColumnValue } from '../model/FlowchartQueryModel';

export interface ColumnInputProps {
  value: string;
  columnValues: ColumnValue[];
  containerClass?: string;
  onChange: (value: string) => void;
}

const ColumnInput = memo((props: ColumnInputProps) => {
  const { columnValues, ...remainingProps } = props;
  // TODO: Render Combobox options with custom rows that show type and description.
  return <Combobox options={columnValues.map((c) => c.value)} filter={true} {...remainingProps} />;
});

export default ColumnInput;
