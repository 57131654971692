import { useCallback, useMemo, useRef } from 'react';

import { autocompletion } from '@codemirror/autocomplete';
import { sql } from '@codemirror/lang-sql';
import { lintGutter } from '@codemirror/lint';
import { Prec } from '@codemirror/state';
import { keymap } from '@codemirror/view';
import CodeMirror from '@uiw/react-codemirror';

import { SqlEditorProps } from '../SqlEditor';

import DoubleClickPlugin from './DoubleClickPlugin';
import EditorToolsPlugin from './EditorToolsPlugin';
import SelectSqlPlugin from './SelectSqlPlugin';
import SnowflakeLinter from './SnowflakeLinter';
import useAutocompleteConfig, { autocompleteTabKeymap } from './useAutocompleteConfig';
import useCodeMirrorTheme from './useCodeMirrorTheme';
import useSqlConfig from './useSqlConfig';

import './CodeMirror.css';

const CodeMirrorSqlEditor = (props: SqlEditorProps) => {
  const {
    id,
    editorHeightCSS,
    editMode,
    // The API and UI buttons(TableExplorer insert buttons, Format SQL button, etc...) need to be able to set the editorSql.
    // Most updates to editorSql will be the user typing, which updates editorSql in page level state.
    // Unfortunatly, this means the user typing will trigger an unnecessary rerender of the CodeMirrorSqlEditor,
    // which might lead to some race conditions between the user typing and the rest of the code base.
    // Right now that concern is out of scope and we will address that if that becomes a problem.
    editorSql,
    selectedSql,
    runErrorLines,
    editorTools,
    // selectedTable,
    queryableTables,
    tablesByFullName,
    searchColumnsByTableID,
    setEditorSql,
    setSelectedSql,
    setSelectedTable,
  } = props;

  // Hack to let SelectSqlPlugin read current selectedSql without triggering a recompute of `extensions`.
  const selectedSqlRef = useRef(selectedSql);
  selectedSqlRef.current = selectedSql;

  const sqlConfig = useSqlConfig(queryableTables, searchColumnsByTableID);
  const autocompleteConfig = useAutocompleteConfig(sqlConfig, tablesByFullName, searchColumnsByTableID);

  const { syntaxHighlightStyle, disabledTheme } = useCodeMirrorTheme();

  const handleChange = useCallback(
    (value: string) => {
      setEditorSql(value, true);
    },
    [setEditorSql],
  );

  // COMMENTARY:
  // Anything that causes `useMemo()` to recompute `extensions`,
  // will cause the `StateEffect.reconfigure` on https://github.com/uiwjs/react-codemirror/blob/master/core/src/useCodeMirror.ts#L162 to be dispatched,
  // which calls the constructors and destructors on all the CodeMirror extensions.
  // AS A RESULT:
  // 1. Avoid typing or any other super frequent action from triggering a useMemo() recompute.
  // 2. If you need to do a super expensive computation to setup an extension,
  //    compute that in a `useMemo()` outside of the extension constructor and pass it in as an argument.
  //    Computing the list of tables for autocomplete would be a textbook example of this concern.
  const extensions = useMemo(() => {
    // sets the Tab key to accept the completion
    // when the completion panel is open
    const runQueryKeymapOverride = Prec.high(
      keymap.of([
        // disable adding new line on cmd+end
        {
          key: 'Mod-Enter',
          preventDefault: false,
          run() {
            return true;
          },
        },
      ]),
    );
    const extensions = [
      runQueryKeymapOverride,
      autocompleteTabKeymap,
      lintGutter(),
      SnowflakeLinter(runErrorLines),
      EditorToolsPlugin(editorTools),
      SelectSqlPlugin(selectedSqlRef, setSelectedSql),
      DoubleClickPlugin(setSelectedTable, queryableTables),
      sql(sqlConfig),
      autocompletion(autocompleteConfig),
      syntaxHighlightStyle,
    ];

    return !editMode ? [...extensions, disabledTheme] : extensions;
  }, [
    disabledTheme,
    syntaxHighlightStyle,
    runErrorLines,
    selectedSqlRef,
    setSelectedSql,
    setSelectedTable,
    queryableTables,
    sqlConfig,
    autocompleteConfig,
    editMode,
    editorTools,
  ]);

  return (
    <CodeMirror
      id={id}
      value={editorSql}
      height={editorHeightCSS}
      extensions={extensions}
      onChange={handleChange}
      readOnly={!editMode}
      editable={editMode}
      autoFocus={editMode}
    />
  );
};

export default CodeMirrorSqlEditor;
