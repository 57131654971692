/*
PasswordInputGroup prefixed by label element and followed by an error element.
*/
import React from 'react';

import PasswordInput from 'components/inputs/basic/PasswordInput/PasswordInput';

import InputGroup, { InputGroupParentProps } from '../InputGroup';

export type SanitizedInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'key' | 'name' | 'value' | 'type'
>;

export interface TextInputGroupProps extends InputGroupParentProps, SanitizedInputProps {
  value: string;
}

export default function PasswordInputGroup(props: TextInputGroupProps) {
  const {
    ref, // Not used. Just don't want it included in ...remainingProps.
    name,
    label,
    postLabelElement,
    key,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    error,
    subscriptDetail,
    subscriptDetailClass,
    ...remainingProps
  } = props;
  const hasError = typeof error === 'string' && error.length > 0;

  return (
    <InputGroup
      name={name}
      input={<PasswordInput className="pr-10" name={name} hasError={hasError} {...remainingProps} />}
      error={error}
      key={key}
      label={label}
      postLabelElement={postLabelElement}
      groupClass={groupClass}
      groupStyle={groupStyle}
      labelClass={labelClass}
      labelStyle={labelStyle}
      subscriptDetail={subscriptDetail}
      subscriptDetailClass={subscriptDetailClass}
    />
  );
}
