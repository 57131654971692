/*
  This expando does the generic expanding and contracting of a "folder-like" item in a FolderExpandoList.
  That folder-like item could be a schema, tag, etc...
*/
import React, { useRef } from 'react';

import cn from 'classnames';

import { AggTable } from 'api/APITypes';

import { PassedToTableRowProps } from '../../TableNameList';
import TableRow from '../../TableRow/TableRow';

import accordion from './Accordion.module.css';

export interface TableExplorerCommonExpandoProps {
  // GenericExpando's props
  isOpen: boolean;
  isFiltering: boolean;
  tables: AggTable[];
  unfilteredCount: number;

  // Stuff GenericExpando passes to TableRow
  selectedTable: AggTable | null; // Used to calculate TableRow.selected
  tableDraggable: boolean;
  hideSchema: boolean;
  passedToTableRow: PassedToTableRowProps;
}

interface TableExplorerGenericExpandoProps extends TableExplorerCommonExpandoProps {
  // The row that is the title of the folder(ie the stuff that does and does not collapse.)
  // This row should have a click handler on it that controls `isOpen`;
  children: React.ReactNode;
}

const TableExplorerGenericExpando = React.memo((props: TableExplorerGenericExpandoProps) => {
  const {
    // My Props
    children,
    isOpen,
    tables,

    // Stuff I pass to TableRow
    selectedTable,
    tableDraggable,
    hideSchema,
    passedToTableRow,
  } = props;

  // Some schemas have a lot of rows which makes it real wasteful(AKA slow) to
  // render all the rows when the schema is closed on initial load; however,
  // the accordionClose animation doesn't work if the content just disappears.
  // So do not render rows until the schema is expanded, but keep rendered rows
  // and only hide with CSS animation when the schema is collapsed.
  const wasOpenRef = useRef(false);
  const renderOpen = isOpen || wasOpenRef.current;
  if (isOpen) {
    wasOpenRef.current = true;
  }

  return (
    <div>
      {children}
      <div
        className={cn(accordion.accordion, {
          [accordion.accordionOpen]: isOpen,
          [accordion.accordionClosed]: !isOpen,
        })}
      >
        {renderOpen && (
          <>
            {tables.map((table: AggTable) => {
              return (
                <TableRow
                  key={table.id}
                  table={table}
                  selected={table.id === selectedTable?.id}
                  draggable={tableDraggable}
                  inFolder={true}
                  hideSchema={hideSchema}
                  {...passedToTableRow}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
});

export default TableExplorerGenericExpando;
