import ServiceBell, { ServiceBellOptions } from '@servicebell/widget';

const SERVICE_BELL_CLIENT_KEY = '9115b8fb69894bf79a4a1de7d3895b27';

// Let app load before launching non-critical ServiceBell.
setTimeout(() => {
  // Docs: https://docs.servicebell.com/controlling-the-widget
  // These options will override the options set at app.servicebell.com.
  const options: ServiceBellOptions = {
    hidden: true, // Default hidden. The header button will show and hide it.
    position: 'right',
    launcher: 'pill',
  };

  ServiceBell('init', SERVICE_BELL_CLIENT_KEY, options);
}, 100);

// There might not be a logged in user.
// When a user logs in, tell ServiceBell so chat conversations have an identified user.
window.userProfileLoaded.then((userProfile) => {
  ServiceBell('identify', userProfile.id, {
    email: userProfile.user.email,
    displayName: userProfile.first_name + ' ' + userProfile.last_name, // Changes the name of the visitor in the dashboard
    companyName: userProfile.company.name,
  });
});
