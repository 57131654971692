/*
This component renders the fraction of the rows in the datatable
that would be visible at a given scroll displacement assuming
we rendered all of the rows in the scrollable area.
As the user scrolls, it rerenders a new set of visible rows and 
offsets them by the scroll displacement.
It uses the same technique as https://github.com/bvaughn/react-window,
but is implmented here because we have a lot of Mozart specfic behaviour.
*/
import React, { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import { Column } from 'api/columnAPI';
import useAnimationFrame from 'hooks/useAnimationFrame';

import { TimeFormat } from './RunResults';
import { SNOWFLAKE_NUMBER_TYPES } from './RunResultsTable';
import { formatTimestamp, formatDate, formatTime } from './tableHelpers';

import rt from 'components/tables/ResultsTable/ResultsTable.module.css';

// Tune these numbers wisely.
// Smaller renders faster.
// Larger means column widths will be less likely to truncate data
// because we will be taking the widest cell of a larger set.
// Default to rendering first one hundred when the table is small.
// This allows for smooth scrolling.
const NORMAL_INIT_RENDER_COUNT = 100;
// Prune more agressively when the table is big
const MANY_COLUMN_INIT_RENDER_COUNT = 40;
const MANY_COLUMN_CUT_OFF = 30;
// We render a few more rows than we have to so that the next scroll might
// not trigger a row rerender. This should enable smoother slow scrolling.
const OVER_RENDER_COUNT = 10;

interface ScrollableDataTableProps {
  scrollRef: React.RefObject<HTMLDivElement>;
  dataRef: React.RefObject<HTMLTableElement>;
  scrollWidth: number;
  scrollHeight: number;
  totalColumnWidth: number;
  headerHeight: number;
  rowHeight: number;
  rows: any[][];
  columns: Column[];
  columnIndexes: number[];
  columnWidths: number[];
  timeFormat: TimeFormat;
  maxLines: number;
  selectedRowIndex: number;
  onTableClick(event: React.MouseEvent<HTMLTableElement>): void;
  onScroll(xOffset: number, yOffset: number): void;
}

export default function ScrollableDataTable(props: ScrollableDataTableProps) {
  const {
    scrollRef,
    dataRef,
    scrollWidth,
    scrollHeight,
    totalColumnWidth,
    headerHeight,
    rowHeight, // Safari Bug: This is wrong when Safari is zoomed out. It works when zoomed at "Actual Size".
    rows,
    columns,
    columnIndexes,
    columnWidths,
    timeFormat,
    maxLines,
    selectedRowIndex,
    onTableClick,
    onScroll,
  } = props;

  // The user's current scroll position as of the last animation frame.
  const offsetRef = useRef({ scrollX: 0, scrollY: 0 });
  // The fraction of rows we rendered(renderedRows) on the previous render based on their last scroll position.
  const renderedRowRef = useRef({ previousStart: 0, start: 0, end: 0, renderedRows: [] as any[][] });
  // The scroll variable that triggers a recomputation of renderedRows.
  const [yScroll, setYScroll] = useState(0);

  // If the list of rows updates, reset all scroll variables.
  useEffect(
    () => {
      // Hopefully, this if saves a render.
      if (yScroll !== 0) {
        setYScroll(0);
      }
      offsetRef.current = { scrollX: 0, scrollY: 0 };
      renderedRowRef.current = { previousStart: 0, start: 0, end: 0, renderedRows: [] as any[][] };
    },
    // DO NOT run effect if yScroll changes.
    // Only run effect on rows changes.
    [rows], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const hasRows = rows.length > 0;
  const memoizedZeroRows = useMemo<React.ReactNode>(
    () =>
      hasRows ? <></> : ZeroRows(dataRef, scrollWidth, scrollHeight, totalColumnWidth, headerHeight),
    [hasRows, dataRef, scrollWidth, scrollHeight, totalColumnWidth, headerHeight],
  );

  useAnimationFrame((deltaTime: number) => {
    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      const scrollX = scrollDiv.scrollLeft;
      const scrollY = scrollDiv.scrollTop;
      const old = offsetRef.current;
      if (scrollX !== old.scrollX || scrollY !== old.scrollY) {
        onScroll(scrollX, scrollY);
      }

      const dataTable = dataRef.current;
      if (dataTable && scrollY !== old.scrollY) {
        const newTableFirstPixel = dataTable.offsetTop;
        const tableHeight = dataTable.offsetHeight;
        const newTableLastPixel = newTableFirstPixel + tableHeight;

        const scrollFirstPixel = scrollY;
        const scrollHeight = scrollDiv.offsetHeight;
        const scrollLastPixel = scrollFirstPixel + scrollHeight;
        // If the scroll area's box no longer completely overlaps all of the
        // renderedRows then trigger a rerender.
        if (newTableFirstPixel > scrollFirstPixel || newTableLastPixel < scrollLastPixel) {
          setYScroll(scrollY);
        }
      }
      offsetRef.current = { scrollX, scrollY };
    }
  });

  /* 
  The same column format functions are used for every row so calculate them once.

  Useful query for testing format functions:

  SELECT current_date, current_time, current_timestamp,
  TO_TIMESTAMP_NTZ(current_timestamp) as NTZ,
  TO_TIMESTAMP_TZ(current_timestamp) as TZ,
  current_date + INTERVAL '6 days' as future_date, 
  current_time + INTERVAL '6 hours' as future_time,
  current_timestamp + INTERVAL '6 hours' as future_timestamp,
  TO_TIMESTAMP_NTZ(current_timestamp) + INTERVAL '6 hours' as future_NTZ,
  TO_TIMESTAMP_TZ(current_timestamp) + INTERVAL '6 hours' as future_TZ
  */
  const columnFunctions = useMemo<any[]>(() => {
    return columnIndexes.map((index: number) => {
      const column = columns[index];
      let formateFunc = null;

      // We don't know the timezone of TIMESTAMP_NTZ so not reformatting those.
      if (column.type === 'TIMESTAMP_TZ' || column.type === 'TIMESTAMP_LTZ') {
        formateFunc = formatTimestamp;
      } else if (column.type === 'DATE') {
        formateFunc = formatDate;
      } else if (column.type === 'TIME') {
        formateFunc = formatTime;
      }
      return formateFunc;
    });
  }, [columns, columnIndexes]);

  const columnAlignmentClasses = useMemo<string[]>(() => {
    return columnIndexes.map((index: number) => {
      const column = columns[index];
      if (SNOWFLAKE_NUMBER_TYPES.includes(column.type)) {
        return ' text-right';
      }
      return '';
    });
  }, [columns, columnIndexes]);

  const [renderedRows, tableDisplacement] = useMemo(() => {
    let { previousStart, start, end, renderedRows } = renderedRowRef.current;
    const newPreviousStart = start;
    let displacement = 0;

    // I don't know my height. Just render the first rows.
    if (scrollHeight === 0 && start === 0 && end === 0 && renderedRows.length === 0) {
      end = NORMAL_INIT_RENDER_COUNT;
      // Some tables have a stupid number of columns render fewer rows in this case.
      if (rows.length > 0 && rows[0].length > MANY_COLUMN_CUT_OFF) {
        end = MANY_COLUMN_INIT_RENDER_COUNT;
      }
      end = Math.min(end, rows.length);
      renderedRows = rows.slice(start, end);
      displacement = 0;
    }
    // I know my height. I can do scroll math.
    else {
      let firstVisibileRow = Math.floor(yScroll / rowHeight);
      let lastVisibileRow = firstVisibileRow + Math.ceil(scrollHeight / rowHeight);
      lastVisibileRow += 1; // Add one to make sure I overflow the end of the window

      // Make sure indices are always in array bounds
      firstVisibileRow = Math.max(firstVisibileRow, 0);
      lastVisibileRow = Math.min(lastVisibileRow, rows.length - 1);

      // end is one more than array index
      const newEnd = lastVisibileRow + 1;

      // Only slice a new renderedRow list if the current one
      // does not contain my desired range
      if (firstVisibileRow < start || newEnd > end) {
        start = firstVisibileRow;
        end = newEnd;

        // Over render in the direction of scrolling so that slow scrolling will
        // have fewer rerenders and therefore smoother scrolling.
        if (start > previousStart) {
          end += OVER_RENDER_COUNT;
        } else {
          start -= OVER_RENDER_COUNT;
        }

        // Make sure indices are always in array bounds
        start = Math.max(start, 0);
        end = Math.min(end, rows.length);

        renderedRows = rows.slice(start, end);
        displacement = start * rowHeight;
      }
      displacement = start * rowHeight;
    }

    renderedRowRef.current = { previousStart: newPreviousStart, start, end, renderedRows };

    return [renderedRows, displacement];
  }, [scrollHeight, rowHeight, rows, yScroll]);

  const memoizedFirstRow = useMemo<React.ReactNode>(
    () =>
      hasRows ? (
        Row(
          renderedRows[0],
          0,
          columnIndexes,
          columnWidths,
          columnFunctions,
          columnAlignmentClasses,
          timeFormat,
          selectedRowIndex,
        )
      ) : (
        <></>
      ),
    [
      hasRows,
      renderedRows,
      columnIndexes,
      columnWidths,
      columnFunctions,
      columnAlignmentClasses,
      timeFormat,
      selectedRowIndex,
    ],
  );

  const memoizedRemainingRows = useMemo<React.ReactNode[]>(
    () =>
      Rows(
        renderedRows,
        1,
        renderedRows.length,
        columnIndexes,
        columnFunctions,
        columnAlignmentClasses,
        timeFormat,
        selectedRowIndex,
      ),
    [renderedRows, columnIndexes, columnFunctions, columnAlignmentClasses, timeFormat, selectedRowIndex],
  );

  const cssScrollWidth = scrollWidth ? `${scrollWidth}px` : '100%';
  const cssScrollHeight = scrollHeight ? `${scrollHeight}px` : '100%';
  const updateHack = Math.random() / 10000;
  const cssTotalColumnWidth = totalColumnWidth ? `${totalColumnWidth + updateHack}px` : undefined;
  const tableStyle = { width: cssTotalColumnWidth, top: `${tableDisplacement}px` };

  let spacerHeight = hasRows && rowHeight ? rows.length * rowHeight : 0;

  return (
    <div
      ref={scrollRef}
      className={rt.runResultsScrollArea}
      style={{ width: cssScrollWidth, height: cssScrollHeight }}
    >
      {hasRows ? (
        <>
          <div className="w-full" style={{ height: `${spacerHeight}px` }}></div>
          <table
            ref={dataRef}
            className={cn(rt.resultsTable, rt.resultsTableResults, rt[`size${maxLines}`])}
            style={tableStyle}
            onClick={onTableClick}
          >
            <tbody>
              {memoizedFirstRow}
              {memoizedRemainingRows}
            </tbody>
          </table>
        </>
      ) : (
        <>{memoizedZeroRows}</>
      )}
    </div>
  );
}

const ZeroRows = (
  dataRef: React.RefObject<HTMLTableElement>,
  scrollWidth: number,
  scrollHeight: number,
  totalColumnWidth: number,
  headerHeight: number,
) => {
  const cssWidth = `${Math.min(scrollWidth, totalColumnWidth)}px`;
  const cssHeight = `${scrollHeight - headerHeight}px`;
  // Resize the zero rows warning text to have an appropriate
  // size for the space available
  // We looked into doing this with CSS based on the container's width
  // and that cannot be done.
  let zeroFontStyle: any = { width: cssWidth, height: cssHeight };
  if (totalColumnWidth) {
    if (totalColumnWidth < 80) {
      zeroFontStyle.fontSize = `1rem`;
    } else if (totalColumnWidth < 150) {
      zeroFontStyle.fontSize = `1.5rem`;
    }
  }

  // Including the table object is a hack to keep references in RunResultsTable
  // from being null. Don't love this but it's way less complicated than
  // refactoring RunResultsTable.
  return (
    <>
      <table ref={dataRef} style={{ width: '0px', height: '0px' }}>
        <tbody></tbody>
      </table>
      <div className={rt.resultsTableNoRows} style={zeroFontStyle}>
        <span>0&nbsp;</span>
        <span>Rows</span>
      </div>
    </>
  );
};

const dataCol = (val: any) => {
  let className = undefined;
  if (val) {
    className =
      val.toString().length <= 64 ? 'whitespace-nowrap' : 'w-[300px] min-w-[300px] break-normal';
  }
  return className;
};

const Row = (
  dataColumns: any[],
  rowIndex: number,
  columnIndexes: number[],
  columnWidths: number[],
  columnFunctions: any[],
  columnAlignmentClasses: string[],
  timeFormat: TimeFormat,
  selectedRowIndex: number,
) => {
  return (
    <tr key={rowIndex}>
      {columnIndexes.map((columnIndex: number, loopIndex: number) => {
        let style = undefined;
        let key = columnIndex;
        if (columnWidths.length) {
          style = { width: `${columnWidths[loopIndex]}px` };

          // We set CSS widths with manual Javascript.
          // React diffs against the virtual DOM and will not overwrite the manual widths
          // unless we force an update.
          key += Math.random();
        }
        let val = dataColumns[columnIndex];
        const isNull = val === null;
        val = isNull ? 'NULL' : String(val);
        if (!isNull) {
          const formatFunc = columnFunctions[loopIndex];
          if (formatFunc) {
            val = formatFunc(val, timeFormat);
          }
        }

        let cName = dataCol(val);
        if (isNull) {
          cName += ' !text-pri-gray-300';
        }
        const columnAlignmentClass = columnAlignmentClasses[columnIndex];
        if (columnAlignmentClass) {
          cName += columnAlignmentClass;
        }
        if (selectedRowIndex === rowIndex) {
          cName += ' !bg-sec-blue-gray-200';
        }

        // tds don't respect max-height so we need to wrap val in a div
        // Extra div is bad for React and CSS performance.
        // The inner div also lets Javascript check to see if the div
        // overflows the td when you double click on the cell.
        return (
          <td key={key} className={cName} style={style}>
            <div>{val}</div>
          </td>
        );
      })}
    </tr>
  );
};

const Rows = (
  rows: any[][],
  start: number,
  end: number,
  columnIndexes: number[],
  columnFunctions: any[],
  columnAlignmentClasses: string[],
  timeFormat: TimeFormat,
  selectedRowIndex: number,
) => {
  const columnWidths: number[] = [];
  return rows
    .slice(start, end)
    .map((dataColumns: any[], rowIndex: number) =>
      Row(
        dataColumns,
        rowIndex + start,
        columnIndexes,
        columnWidths,
        columnFunctions,
        columnAlignmentClasses,
        timeFormat,
        selectedRowIndex,
      ),
    );
};
