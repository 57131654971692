import React, { HTMLAttributes } from 'react';

import { InfoCircleFill, Icon } from 'react-bootstrap-icons';

import cn from 'classnames';

import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';
import { Placement } from 'components/overlay/PopperTrigger/usePopperRefs';

import s from './InfoIcon.module.css';

interface InfoIconProps {
  title?: string;
  content: React.ReactNode;
  placement?: Placement;
  icon?: Icon;
  iconColor?: string;
  iconRotate?: number; // the number of degrees like `45`
  containerClass?: string;
  popoverProps?: HTMLAttributes<HTMLDivElement>;
}

export default function InfoIcon(props: InfoIconProps) {
  const { title, content, containerClass, popoverProps } = props;
  let { placement, icon, iconColor, iconRotate } = props;
  placement = placement || 'right';

  const MyIcon = !!icon ? icon : InfoCircleFill;
  iconColor = iconColor || 'var(--pri-gray-400)';
  const rotateClass = iconRotate !== undefined ? `rotate-[${iconRotate}deg]` : '';
  const actualClass = cn(s.icon, rotateClass);

  return (
    <PopperTrigger
      placement={placement}
      triggers="hover_click_lock"
      popoverProps={popoverProps}
      renderPopper={(popperProps) => {
        return <Popover title={title} content={content} {...popperProps} />;
      }}
    >
      <div className={containerClass}>
        <div className={s.inlineIconBox}>
          <MyIcon color={iconColor} size={16} className={actualClass} />
        </div>
      </div>
    </PopperTrigger>
  );
}
