/**
 * This file converts FlowchartExpressions into SQLGlotExpressions.
 * The SQLGlotExpressions are then submitted to the API for conversion to SQL.
 */
import { AggTable } from 'api/tableAPI';

import { ColumnValue, FlowchartQueryModel, getVertex, SourceTable } from '../model/FlowchartQueryModel';

import { FlowchartExpression } from './flowchartExpressionBuilder';
import {
  Identifier,
  Column,
  Expression,
  Star,
  From,
  Select,
  SelectExpression,
  Table,
  ComparisonClass,
  Comparison,
  Join,
  Alias,
  TableAlias,
} from './SQLGlotExpression';

export function buildGlotExpression(
  fqm: FlowchartQueryModel,
  flowchartExpression: FlowchartExpression,
): Expression {
  const { select, from, join } = flowchartExpression;
  // eslint-disable-next-line no-console
  // console.log('buildGlotExpression.flowchartExpression', flowchartExpression);

  if (from && join) {
    // This is a programmer error.
    // Only one of these should define the from table at a given time.
    throw new Error(ERROR_FROM_AND_JOIN);
  }

  if (!from && !join) {
    throw new Error(ERROR_NO_FROM);
  }

  let from_: From | undefined = undefined;
  let joins_: Join[] | undefined = undefined;

  if (from) {
    if (from.table === null) {
      throw new Error(ERROR_NO_FROM_TABLE);
    }
    from_ = getFrom(from.table);
  }

  let selectedColumns = select?.selectedColumns;

  let tableAliases: Record<string, string> = {};
  if (join) {
    if (join.leftParentID === null) {
      throw new Error(ERROR_JOIN_RECURSION_NOT_SUPPORTED);
    }
    if (join.rightParentID === null) {
      throw new Error(ERROR_JOIN_RECURSION_NOT_SUPPORTED);
    }
    const leftParent = getVertex(fqm, join.leftParentID);
    const rightParent = getVertex(fqm, join.rightParentID);
    if (leftParent.type !== 'source_table') {
      throw new Error(ERROR_JOIN_RECURSION_NOT_SUPPORTED);
    }
    if (rightParent.type !== 'source_table') {
      throw new Error(ERROR_JOIN_RECURSION_NOT_SUPPORTED);
    }
    const leftSource = leftParent as SourceTable;
    const rightSource = rightParent as SourceTable;
    if (leftSource.table === null) {
      throw new Error(ERROR_JOIN_SET_LEFT_TABLE);
    }
    if (rightSource.table === null) {
      throw new Error(ERROR_JOIN_SET_RIGHT_TABLE);
    }
    if (leftSource.alias === null) {
      throw new Error(ERROR_JOIN_SET_LEFT_TABLE);
    }
    if (rightSource.alias === null) {
      throw new Error(ERROR_JOIN_SET_RIGHT_TABLE);
    }
    from_ = getFrom(leftSource.table, leftSource.alias);
    joins_ = [
      getJoin(rightSource.table, leftSource.alias, rightSource.alias, join.leftColumn, join.rightColumn),
    ];
    tableAliases[leftSource.table.full_name] = leftSource.alias;
    tableAliases[rightSource.table.full_name] = rightSource.alias;
    selectedColumns = join.selectedColumns;
  }

  const expressions = getExpressions(selectedColumns, tableAliases);

  const result: Select = {
    class: 'Select',
    args: {
      expressions,
      from: from_ as From,
      joins: joins_,
    },
  };

  return result;
}

export function getExpressions(
  selectedColumns: ColumnValue[] | undefined,
  tableAliases: Record<string, string>,
): SelectExpression[] {
  // Default to *
  let expressions: SelectExpression[] = [getStar()];

  // Prefix joined *s with aliases
  const tableNames = Object.values(tableAliases);
  if (tableNames.length) {
    expressions = tableNames.map((tn) => getStarColumn(tn));
  }

  // The user selected specific columns and/or column order
  if (!!selectedColumns) {
    expressions = selectedColumns
      .filter((sc) => sc.picked)
      .map((sc) => getAliasOrColumn(sc, tableAliases));
  }

  return expressions;
}

export function getFrom(table: AggTable, tableAlias?: string): From {
  return {
    class: 'From',
    args: {
      this: getTable(table, tableAlias),
    },
  };
}

export function getTable(table: AggTable, tableAlias?: string): Table {
  const result: Table = {
    class: 'Table',
    args: {
      this: getIdentifier(table.name, false),
      db: getIdentifier(table.schema, false),
    },
  };

  if (tableAlias) {
    result.args.alias = getTableAlias(tableAlias);
  }

  return result;
}

export function getTableAlias(tableAlias: string): TableAlias {
  return {
    class: 'TableAlias',
    args: { this: getIdentifier(tableAlias, false) },
  };
}

export function getStar(): Star {
  return {
    class: 'Star',
    args: {},
  };
}

export function getStarColumn(alias: string): Column {
  return {
    class: 'Column',
    args: {
      this: getStar(),
      table: getIdentifier(alias, false),
    },
  };
}

export function getColumn(value: string, quoted: boolean, tableAlias?: string): Column {
  const column: Column = {
    class: 'Column',
    args: {
      this: getIdentifier(value, quoted),
    },
  };

  if (tableAlias) {
    column.args.table = getIdentifier(tableAlias, false);
  }

  return column;
}

export function getAliasOrColumn(cv: ColumnValue, tableAliases: Record<string, string>): Column | Alias {
  const column = getColumn(cv.value, false, tableAliases[cv.table?.full_name || '']);

  if (cv.alias) {
    const alias: Alias = {
      class: 'Alias',
      args: {
        this: column,
        alias: getIdentifier(cv.alias, false),
      },
    };
    return alias;
  }
  return column;
}

export function getIdentifier(value: string, quoted: boolean): Identifier {
  return {
    class: 'Identifier',
    args: {
      this: value,
      quoted,
    },
  };
}

export function getComparison(
  comparator: ComparisonClass,
  leftSide: Expression,
  rightSide: Expression,
): Comparison {
  return {
    class: comparator,
    args: {
      this: leftSide,
      expression: rightSide,
    },
  };
}

export function getJoin(
  rightTable: AggTable,
  leftTableAlias: string,
  rightTableAlias: string,
  leftColumn: string,
  rightColumn: string,
): Join {
  return {
    class: 'Join',
    args: {
      this: getTable(rightTable, rightTableAlias), // The table after `on`, not the table after `from`
      on: getComparison(
        'EQ',
        getColumn(leftColumn, false, leftTableAlias),
        getColumn(rightColumn, false, rightTableAlias),
      ),
    },
  };
}

export const ERROR_NO_FROM = 'You must set from.';
export const ERROR_NO_FROM_TABLE = 'You must set the table on your from.';
export const ERROR_FROM_AND_JOIN = 'From and join not supported.';
export const ERROR_JOIN_RECURSION_NOT_SUPPORTED =
  'At present, you can only join directly from source_tables.';
export const ERROR_JOIN_SET_LEFT_TABLE = 'You must set the left table of a join.';
export const ERROR_JOIN_SET_RIGHT_TABLE = 'You must set the right table of a join.';
