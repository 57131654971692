/*
 * Type definitions, API methods, and conversion utilities for Tables.
 */
import API from 'api/API';
import { Connector, TransformRun, TransformVersion, Tag, DatabaseType } from 'api/APITypes';
import DataAPI from 'api/DataAPI';
import { convertSchemaName, convertTableName, convertFullName } from 'utils/dbName';

import { CSVUpload } from './csvUploadAPI';
import { DbtDestinationTable, DbtRunConfig } from './dbtAPI';

/*******************************************************************************
 * Types
 ******************************************************************************/
// TableType is better described as the agent responsible for creating the table
export type TableType = 'unmanaged' | 'csv_upload' | 'transform' | 'snapshot' | 'dbt';

export type ScheduleMode = 'cron' | 'all_marked' | 'any_marked';

export type DatabaseTableType =
  | 'BASE TABLE'
  | 'VIEW'
  | 'SECURE VIEW'
  | 'MATERIALIZED VIEW'
  | 'LOCAL TEMPORARY'
  | 'GLOBAL TEMPORARY';

export type CreateAsType = 'BASE TABLE' | 'VIEW' | 'SECURE VIEW';

// This Transform should directly reflect the Transform Serializer in the backend
// TODO Move this interface and the rest of the transform interfaces in APITypes to api/Transforms.
export interface Transform {
  id: string;
  sql: string;
  schedule_mode: ScheduleMode;
  scheduled: boolean;
  schedule: string;
  table_id: string;
  last_completed_run: TransformRun | null;
  current_version: TransformVersion;
  incremental: boolean;
  incremental_update_key: string | null;
  auto_rebuild_on_change: boolean;
  create_as: CreateAsType;
  primary_key: string | null;
  cluster_by: string | null;
  updated_at: string;
}

// This Table should directly reflect the Table Serializer in the backend
export interface Table {
  id: string;
  name: string;
  schema: string;
  num_rows: number;
  transform_id: string | null;
  type: TableType;
  django_thinks_exists_in_snowflake: boolean;
  last_snapshot_queue_time: string | null;
  last_snapshot_at: string | null;
  snapshot: boolean;
  description: string;
  tags: string[];
  database_table_type: DatabaseTableType;
  view_sql: string | null;
  dbt_destination_table_ids: string[];
}

export interface SaveableTableProps {
  name?: string;
  schema?: string;
  description?: string;
  snapshot?: boolean;
}

// Aggregated Tables contain transforms, connectors, tags,
// and any other data not returned by the backend table serializer.
// The frontend appends values from other API calls or computed values to this model.
// As of Nov 3, 2021, the additional API calls are in `useTableModelContext()`
// and the data is combined in `AggTableReducer`.
export interface AggTable extends Table {
  full_name: string;
  transform: Transform | null;
  connector: Connector | null;
  csvUpload: CSVUpload | null;
  // Django's default serialization scheme, bless its heart, returns tag ids
  // under the name `tags` which sounds like an array of objects, but is not.
  // Creating property `tagObjs` to store tag ojects is the least bad thing we can do.
  // `AggTableReducer` will populate the `tabObjs` array.
  tagObjs: Tag[];
  dbtRunConfigs: DbtRunConfig[];
  dbtDestinationTables: DbtDestinationTable[];
}

export interface AggRecentTable extends AggTable {
  last_visited_at: string;
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const tableAPI = {
  get: (id: string, databaseType: DatabaseType) => {
    const api = new API();
    return api.get(`/api/tables/${id}`, (table) => convertTable(table, databaseType));
  },

  patch: (id: string, tableProps: SaveableTableProps, databaseType: DatabaseType) => {
    const api = new DataAPI();
    return api.patch(`/api/tables/${id}`, tableProps, (table) => convertTable(table, databaseType));
  },

  setTags: (id: string, tagIDs: string[], databaseType: DatabaseType) => {
    const api = new API();
    return api.post(`/api/tables/${id}/tags`, { tags: tagIDs }, (table) =>
      convertTable(table, databaseType),
    );
  },
};
export default tableAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
export function convertTable(table: Table, databaseType: DatabaseType): AggTable {
  const { schema, name, ...rest } = table;
  const aggTable: AggTable = {
    ...rest,
    full_name: convertFullName(table, databaseType),
    schema: convertSchemaName(table, databaseType),
    name: convertTableName(table, databaseType),
    transform: null,
    connector: null,
    csvUpload: null,
    tagObjs: [],
    dbtRunConfigs: [],
    dbtDestinationTables: [],
  };

  return aggTable;
}

export interface TableResponse {
  last_tables_change: string;
  tables: Table[];
}

export interface AggTableResponse {
  last_tables_change: string;
  tables: AggTable[];
}

export function convertTables(tablesResp: TableResponse, databaseType: DatabaseType): AggTableResponse {
  return {
    last_tables_change: tablesResp.last_tables_change,
    tables: tablesResp.tables.map((t: Table) => convertTable(t, databaseType)),
  };
}
