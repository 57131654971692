import React from 'react';

import classNames from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';
import CenterIcon from 'components/primitives/icons/CenterIcon/CenterIcon';

import styles from './FlowchartControls.module.css';

interface Props {
  className?: string;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onResetView: () => void;
}

const FlowchartControls = (props: Props) => {
  const { className, onZoomIn, onZoomOut, onResetView } = props;
  return (
    <div className={classNames(styles.controls, className)}>
      <IconButton variant="lightDullAction" icon="ZoomIn" text="Zoom in" textSrOnly onClick={onZoomIn} />
      <IconButton
        variant="lightDullAction"
        icon="ZoomOut"
        text="Zoom out"
        textSrOnly
        iconLast
        onClick={onZoomOut}
      />
      <IconButton
        variant="lightDullAction"
        customIcon={CenterIcon}
        text="Reset graph view"
        textSrOnly
        onClick={onResetView}
      />
    </div>
  );
};

export default FlowchartControls;
