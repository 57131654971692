import React from 'react';

import cn from 'classnames';

import TextInputGroup from 'components/inputs/group/TextInputGroup/TextInputGroup';
import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';

import { ColumnValue } from '../../../../../model/FlowchartQueryModel';

import SelectColumnRowHoverCard from './SelectColumnsRowHoverCard/SelectColumnsRowHoverCard';

// Type serves an a reminder of what type of string to use.
export type ColumnValueID = ColumnValue['id'];

interface SelectColumnsRowProps {
  columnValue: ColumnValue;
  aliasError: string;
  samples: string[];
  isBeingDragged: boolean;
  isDraggedOver: boolean;
  onDragStart: (column: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDragEnd: () => void;
  onDragOver: (event: React.DragEvent<HTMLSpanElement>) => void;
  onDragEnter: (enteredColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDragLeave: (leftColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDrop: (droppedOnColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onUpdateAlias: (column: ColumnValueID, alias: string) => void;
}

const SelectColumnsRow = React.memo((props: SelectColumnsRowProps) => {
  const {
    columnValue,
    aliasError,
    samples,
    isBeingDragged,
    isDraggedOver,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    onUpdateAlias,
  } = props;
  const { id, value, alias } = columnValue;

  const handleOnChangeAlias = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateAlias(id, event.target.value);
  };

  const handleOnClickAlias = (event: React.MouseEvent<HTMLInputElement>) => {
    // Prevent row click handler from checking/unchecking the row
    event.stopPropagation();
  };

  const involvedInDrag = isBeingDragged || isDraggedOver;

  return (
    <div
      className={cn('w-full p-1', {
        'opacity-25': isBeingDragged,
        'border border-sec-blue-gray-500': isBeingDragged,
        'bg-pri-success-200': isDraggedOver,
        'shadow-[0_0_0_0.3rem_var(--pri-success-400)]': isDraggedOver,
      })}
      draggable={true}
      onDragStartCapture={(e) => onDragStart(id, e)}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={(e) => onDragEnter(id, e)}
      onDragLeave={(e) => onDragLeave(id, e)}
      onDrop={(e) => onDrop(id, e)}
    >
      <div className="w-full f-between">
        <div className="flex-1">
          <PopperTrigger
            forceShow={involvedInDrag ? false : undefined}
            placement="top"
            triggers="hover"
            delay={{ show: 400, hide: 50 }}
            popoverProps={{ style: { maxWidth: '400px' } }}
            renderPopper={(popperProps) => {
              return (
                <Popover
                  content={<SelectColumnRowHoverCard columnValue={columnValue} samples={samples} />}
                  {...popperProps}
                />
              );
            }}
          >
            <div>{value}</div>
          </PopperTrigger>
        </div>
        <TextInputGroup
          name={value}
          value={alias || ''}
          error={aliasError}
          onChange={handleOnChangeAlias}
          onClick={handleOnClickAlias}
          groupClass="w-[300px]"
        />
      </div>
    </div>
  );
});

export default SelectColumnsRow;
