export interface ToolbarItemProps {
  title: string;
  description: string;
  img: string;
}

const ToolbarItem = (props: ToolbarItemProps) => {
  const { title, description, img } = props;
  return (
    <div className="f-col">
      <div className="font-medium">{title}</div>
      <div className="mt-2 text-pri-gray-400">{description}</div>
      <img src={`/images/diagrams/flowchart_editor/${img}`} alt={`${title} diagram`} className="mt-2" />
    </div>
  );
};

export default ToolbarItem;
