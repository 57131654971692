/*
TextInput prefixed by label element and followed by an error element.
*/
import React from 'react';

import TextInput, { TextInputType } from 'components/inputs/basic/TextInput/TextInput';

import InputGroup, { InputGroupParentProps } from '../InputGroup';

export type SanitizedInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'key' | 'name' | 'value'
>;

export interface TextInputGroupProps extends InputGroupParentProps, SanitizedInputProps {
  value: string | number;
  type?: TextInputType;
}

export default function TextInputGroup(props: TextInputGroupProps) {
  const {
    ref, // Not used. Just don't want it included in ...remainingProps.
    name,
    type,
    label,
    postLabelElement,
    key,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    error,
    subscriptDetail,
    subscriptDetailClass,
    ...remainingProps
  } = props;
  const hasError = typeof error === 'string' && error.length > 0;

  return (
    <InputGroup
      name={name}
      input={<TextInput type={type} name={name} hasError={hasError} {...remainingProps} />}
      subscriptDetail={subscriptDetail}
      subscriptDetailClass={subscriptDetailClass}
      error={error}
      key={key}
      label={label}
      postLabelElement={postLabelElement}
      groupClass={groupClass}
      groupStyle={groupStyle}
      labelClass={labelClass}
      labelStyle={labelStyle}
    />
  );
}
