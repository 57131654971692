import { useContext } from 'react';

import API from 'api/API';
import { DbtRunConfig, DbtRunConfigUpsertParams } from 'api/dbtAPI';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import ConnectGitRepo from 'pages/dbt/AddDBTRunConfig/ConnectGitRepo';
import useGitReposState from 'pages/dbt/AddDBTRunConfig/useGitReposState';

import RunConfigForm from '../../shared/RunConfigForm/RunConfigForm';

interface EditModetProps {
  runConfig: DbtRunConfig;
  setEditingConfiguration: (editing: boolean) => void;
}

const EditMode = (props: EditModetProps) => {
  const { runConfig, setEditingConfiguration } = props;
  const { updateDbtRunConfigs } = useContext(TableModelsContext);
  const {
    loading,
    loadingError,
    newestSshKey,
    repos,
    showConnectNewGitRepo,
    setShowConnectNewGitRepo,
    setRepos,
  } = useGitReposState();

  // RunConfigFormProps catches this function's exceptions.
  const doSave = async (newValues: DbtRunConfigUpsertParams) => {
    const api = new API();
    const response = await api.patch(`api/dbt_run_configurations/${props.runConfig.id}`, newValues);
    const updatedDbtConfig = response.data as DbtRunConfig;
    updateDbtRunConfigs([updatedDbtConfig]);
    analytics.track('ShowDBTRunConfigSettingsTabEditMode SaveUpdate', newValues);
    setEditingConfiguration(false);
  };

  const handleCancel = () => {
    analytics.track('ShowDBTRunConfigSettingsTabEditMode CancelEditMode');
    setEditingConfiguration(false);
  };

  const handleOpenAddNewRepo = () => {
    setShowConnectNewGitRepo(true);
    analytics.track('ShowDBTRunConfigSettingsTabEditMode OpenAddGitRepo');
  };

  const initialValues: DbtRunConfigUpsertParams = {
    name: runConfig.name,
    dbt_repository: runConfig.dbt_repository.id,
    target_schema: runConfig.target_schema,
    dbt_version: runConfig.dbt_version,
    commands: runConfig.commands,
    scheduled: runConfig.scheduled,
    schedule_mode: runConfig.schedule_mode,
    schedule: runConfig.schedule,
    connector_dependencies: runConfig.connector_dependencies,
    custom_git_branch:
      runConfig.use_custom_git_branch && runConfig.custom_git_branch ? runConfig.custom_git_branch : '',
  };

  return (
    <TabLayout loading={loading} error={loadingError}>
      {newestSshKey && showConnectNewGitRepo && (
        <ConnectGitRepo
          publicKey={newestSshKey}
          repos={repos}
          setRepos={setRepos}
          setShowConnectNewGitRepo={setShowConnectNewGitRepo}
        />
      )}
      {repos.length && !showConnectNewGitRepo && (
        <CenteredContainer maxWidth="814px" containerClass="!mt-0">
          <RunConfigForm
            initialValues={initialValues}
            doSave={doSave}
            onCancel={handleCancel}
            repos={repos}
            onOpenNewRepoForm={handleOpenAddNewRepo}
          />
        </CenteredContainer>
      )}
    </TabLayout>
  );
};

export default EditMode;
