/*
This is the TableExplorer used in form inputs.

Note that it memoizes everything to reduce the number of rerenders.
The TableExplorer component hierarchy is rather expensive.
*/
import React, { useCallback, useEffect } from 'react';

import { AggTable } from 'api/APITypes';
import { Column } from 'api/columnAPI';
import TableExplorerBase from 'components/query/TableExplorer/TableExplorerBase';
import useTableExplorerReducer from 'components/query/TableExplorer/useTableExplorerReducer';

import TableRowHoverOverlay from './TableRowHoverOverlay';

interface TablePickerExplorerProps {
  selectedTable: AggTable | null;
  tableDraggable: boolean;
  tablesNamesUsedInSql: string[];
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onPickTable(table: AggTable): void;
}

const TablePickerExplorer = React.memo((props: TablePickerExplorerProps) => {
  const { tablesNamesUsedInSql, onPickTable } = props;

  const tableExplorerReduction = useTableExplorerReducer('TablePickerExplorer');
  const { setTableNamesInSql } = tableExplorerReduction;

  /*******************************************************************************
   * Overrides relevant to TablePicker
   ******************************************************************************/
  useEffect(() => {
    setTableNamesInSql(tablesNamesUsedInSql);
  }, [tablesNamesUsedInSql, setTableNamesInSql]);

  /*******************************************************************************
   * Overrides relevant to TableRows In TableList(s)
   ******************************************************************************/
  const handlePickTable = useCallback(
    (table: AggTable) => {
      analytics.track('TablePickerExplorer PickTable');
      return onPickTable(table);
    },
    [onPickTable],
  );

  const handleDoubleClickTable = useCallback((table: AggTable) => {
    // Do nothing for now. Yay!
  }, []);

  const renderTableHover = useCallback(
    (
      hoveredIndex: number,
      hoveredTable: AggTable,
      selectedTable: AggTable | null,
      overlayRight: number,
    ) => {
      return (
        <TableRowHoverOverlay
          hoveredIndex={hoveredIndex}
          table={hoveredTable}
          selected={hoveredTable.id === selectedTable?.id}
          overlayRight={overlayRight}
          onPickTable={handlePickTable}
        />
      );
    },
    [handlePickTable],
  );

  /*******************************************************************************
   * Overrides relevant to ColumnRows in TableDetails
   ******************************************************************************/
  const renderColumnHover = useCallback((column: Column, overlayRight: number) => {
    // Do nothing for now. Yay!
    return null;
  }, []);

  const handleDoubleClickColumn = useCallback((column: Column) => {
    // Do nothing for now. Yay!
  }, []);

  return (
    <>
      <TableExplorerBase
        showPinned={tablesNamesUsedInSql.length > 0}
        onlyAutoPin={true}
        {...props}
        tableExplorerReduction={tableExplorerReduction}
        eventLocation="TablePickerExplorer"
        onDoubleClickTable={handleDoubleClickTable}
        onDoubleClickColumn={handleDoubleClickColumn}
        renderTableHover={renderTableHover}
        renderColumnHover={renderColumnHover}
      />
    </>
  );
});

export default TablePickerExplorer;
