import React, {
  ChangeEvent,
  KeyboardEvent,
  SetStateAction,
  useState,
  Dispatch,
  useLayoutEffect,
  useRef,
} from 'react';

import { debounce } from 'lodash';

import Button from 'components/inputs/basic/Button/Button';
import ExpandingTextAreaInput from 'components/inputs/basic/ExpandingTextAreaInput/ExpandingTextAreaInput';
import { AIAssistantChatMessage } from 'components/query/useAIAssistant';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import useAIPermissionModalContext from 'model_layer/useAIPermissionModalContext';

import { EditorRunProps } from '../useSqlEditor';

import AIChatHistory from './AIChatHistory';

interface AIAssistantEditorProps {
  question: string;
  messages: AIAssistantChatMessage[];
  askingAssistant: boolean;
  assistantError: string;
  loadingChatHistory: boolean;
  cancellingQuestion: boolean;
  setMessages: Dispatch<SetStateAction<AIAssistantChatMessage[]>>;
  setQuestion: (question: string) => void;
  setSqlAndRun: (sql: string) => Promise<EditorRunProps>;
  handleAskAssistant: (question: string) => void;
  handleCancelQuestion: () => void;
}

const AIAssistantEditor = (props: AIAssistantEditorProps) => {
  const {
    question,
    messages,
    askingAssistant,
    assistantError,
    loadingChatHistory,
    cancellingQuestion,
    setMessages,
    setQuestion,
    handleAskAssistant,
    setSqlAndRun,
    handleCancelQuestion,
  } = props;
  const [showChatAdvice, setShowChatAdvice] = useState(true);
  const { setShowAIPermissionModal } = useAIPermissionModalContext();
  const {
    userProfile: {
      company: { allow_ai },
    },
  } = useUserProfile();
  const chatWindowRef = useRef<HTMLDivElement>(null); // This is for auto-scrolling to the bottom on the chat window
  const lastOffsetHeightRef = useRef(0);

  // The user might be dragging component edge or browser edge to resize something.
  // Debounce scroll to keep things responsive.
  const scrollToBottom = debounce(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTo({
        top: chatWindowRef.current.scrollHeight,
        behavior: 'auto',
      });
    }
  }, 100);

  // When a new chat gets added, scroll to the bottom
  useLayoutEffect(
    () => {
      // 08/13/2024:
      // KNOWN ISSUE: ADVISE NOT WORRY ABOUT FOR A WHILE
      // 1. Clicking the setSQLAndRun button can cause the <QueryEditor/> to unmount and remount <AIAssistantEditor/>(or any of it's child components)
      // 2. This is a know structural issue with <QueryEditor/> that John has spent a long time trying to fix to no avail.
      // 3. It is maybe possible to get this button to be less likely to cause the unmount. Actually fixing the <QueryEditor/>
      //    is an inherently hard problem.
      // 4. I believe the code in this file to be correct provided that <AIAssstantEditor /> is only mounted once
      //    over its lifetime and `messages` is only updated when a scroll is appropriate.
      //
      // How to reproduce:
      // 1. Load an AI chat with many messages.
      // 2. Scroll to the top.
      // 3. Click the setSQLAndRun() button.
      // 4. BUG: This component will get unmounted and remounted causing `scrollToBottom()` to be run inappropriately.
      scrollToBottom();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages],
  );

  // When the window shrinks, scroll to the bottom.
  // 08/13/2024: John put this here for a good reason, but forgot exactly why
  // and is becoming less certain we should still have this.
  // Don't delete this on just for the sake of less code. This was important once.
  useLayoutEffect(() => {
    const el = chatWindowRef.current;
    if (el) {
      const windowShrank = lastOffsetHeightRef.current > el.offsetHeight;
      if (windowShrank) {
        scrollToBottom();
      }
      lastOffsetHeightRef.current = el.offsetHeight;
    }
  });

  const doSubmit = (question: string) => {
    // If the user does not have permission to use the AI assistant, show the permission modal
    if (!allow_ai) {
      setShowAIPermissionModal(true);
    } else {
      setShowChatAdvice(false);
      setQuestion(question);
      setMessages((prevChat) => {
        return [...prevChat, { role: 'user', message: question }];
      });
      handleAskAssistant(question);
    }
  };

  const handleClickSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    doSubmit(question);
  };

  const handleChangeQuestion = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(event.target.value);
  };

  const handleKeySubmit = (event: KeyboardEvent) => {
    if (!event.shiftKey && event.key === 'Enter' && question !== '') {
      doSubmit(question);
    }
  };

  const actuallyShowChatAdvice = showChatAdvice && messages.length === 0;

  if (loadingChatHistory) {
    return (
      <div className="w-full h-full flex f-col-center">
        <div>Loading chat history...</div>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col justify-between bg-sec-blue-gray-100">
      <div ref={chatWindowRef} className="w-full h-full overflow-auto flex flex-col">
        <AIChatHistory
          messages={messages}
          actuallyShowChatAdvice={actuallyShowChatAdvice}
          setSqlAndRun={setSqlAndRun}
        />
      </div>
      <div className="p-2 flex flex-col border-t-[1px] border-solid border-sec-blue-gray-50">
        {askingAssistant && (
          <div className="f-between">
            <div className="text-sm f-col-center mb-2">
              <div className="mx-4">
                The assistant is collecting metadata and verifying SQL, so it may take some time to
                respond...
              </div>
            </div>
            <Button
              spinning={cancellingQuestion}
              variant="darkDanger"
              size="form"
              onClick={handleCancelQuestion}
            >
              Cancel
            </Button>
          </div>
        )}
        <form onSubmit={handleClickSubmit}>
          <div className="f-row-y-center">
            <ExpandingTextAreaInput
              name="question"
              value={question}
              onChange={handleChangeQuestion}
              onKeyDown={handleKeySubmit}
              disabled={askingAssistant}
            />
            <Button
              type="submit"
              spinning={askingAssistant}
              variant="darkDullAction"
              size="form"
              className="ml-2 self-end"
              disabled={
                askingAssistant ||
                (!question && !(!!messages.length && messages[messages.length - 1].role === 'user'))
              }
            >
              Ask
            </Button>
          </div>
        </form>
        {assistantError && (
          <Alert variant="error" className="mt-2">
            {assistantError}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default AIAssistantEditor;
